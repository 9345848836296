import axios from "axios"
import { urlBase } from "./API"

// Api For Add New Session
export const addHomeWork = (data) => {
    return axios({
        method: "PUT",
        url: `${urlBase}/api/add-new-homework`,
        data: data
        // {
        //     "classId": "66caf5638fd90bc8ad0744b5",
        //     "sessionId": "66cb9e18b19f461716098660",
        //     "studentId": "66cb971bbb2816dda359e82b",
        //     "file": "www.google.com"
        // }
    })
}
export const uploadFileLocally = (data) => {
    return axios({
        method: "PUT",
        url: `${urlBase}/api/add-new-homework`,
        data: data
        // {
        //     "classId": "66caf5638fd90bc8ad0744b5",
        //     "sessionId": "66cb9e18b19f461716098660",
        //     "studentId": "66cb971bbb2816dda359e82b",
        //     "file": "www.google.com"
        // }
    })
}



// Add New Home Work Task

export const addNewHomeWorktask = (data) => {
    return axios({
        method: "PUT",
        url: `${urlBase}/api/add-new-homework-task`,
        data: data
        // {
        //     "classId": "66caf5638fd90bc8ad0744b5",
        //     "sessionId": "66cb9e18b19f461716098660",
        //    
        //     "homeWorkTask": "sss"
        // }
    })
}


// Downlaod HomeWork Api


export const downloadHomeWork = (data) => {
    return axios({
        method: "POST",
        url: `${urlBase}/api/download-student-homework`,
        data: data
        // {
        //     "classId": "66caf5638fd90bc8ad0744b5",
        //     "studentId": "66cb971bbb2816dda359e82b",
        //     "sessionId": "66d44d9d7fead6c82641fa34"
        // }
    })
}
