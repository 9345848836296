import React, { useEffect, useState } from 'react';
import { Modal, Form, Button, ListGroup, Alert } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Update_Level } from '../../../actions/curriculums/levels';
import { HomePage } from '../../../translations/trans';

const ModalAddMaterial = ({ materials, levelId, materialIDS }) => {

  const [choosed, setChoosed] = useState([]);
  const [show, setShow] = useState(false);
  const [message, setMessage] = useState(null)
  // 
  useEffect(() => {
    const currentMaterials = materials.filter(m => materialIDS.includes(m._id));
    setChoosed(currentMaterials)
  }, [])
  console.log('choosed', choosed)
  const handleShowAddMaterialModal = () => {
    setShow(true)
  }
  const handleCloseAddMaterialModal = () => {
    setShow(false)
  }
  const handleMaterialSelection = (selectedMaterial) => {
    // Handle the selected material
    const checkexistance = choosed.filter(m => m._id === selectedMaterial);
    if (checkexistance.length) return;
    const material = materials.filter(m => m._id === selectedMaterial)
    setChoosed([...choosed, ...material]);

  };

  const handleRemoveMaterial = (materialId) => {
    // Remove the selected material
    const updatedChoosed = choosed.filter((material) => material._id !== materialId);
    setChoosed(updatedChoosed);
  };



  const dispatch = useDispatch();
  // Submite materials
  const submiteMaterials = async () => {
    try {
      const newMaterialIDS = [...materialIDS];
      const oldMaterialIDSObject = {};
      materialIDS.forEach(m => oldMaterialIDSObject[m] = m);

      choosed.forEach(c => {
        if (!oldMaterialIDSObject[c._id]) {
          newMaterialIDS.push(c._id);
        }
      });
      const res = await dispatch(Update_Level({ _id: levelId, materialIDS: newMaterialIDS }));
      setMessage({ case: true, message: res.data.message });
    } catch (err) {
      console.warn(err);
      setMessage({ case: false, message: err.message })
    }
  }

  useEffect(() => {
    setTimeout(() => {
      setMessage(null)
    }, 4000)
  }, [message])
  const { lang, dir } = useSelector(({ language }) => {
    return { lang: language.lang, dir: language.dir };
  });
  return (
    <>
      <Button variant="success" onClick={() => {

        handleShowAddMaterialModal()
      }}>
        {HomePage.AddMaterial[lang]}
      </Button>
      {/* Modal for Adding Material */}
      <Modal show={show} onHide={handleCloseAddMaterialModal} dir={`${dir}`}>
        <Modal.Header closeButton>
          <Modal.Title>{HomePage.AddMaterial[lang]}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* Include your form or content for adding material */}

          <Form>
            <Form.Group controlId="formMaterial">
              <Form.Label>{HomePage.chooseMaterial[lang]}</Form.Label>
              <Form.Control as="select" onChange={(e) => handleMaterialSelection(e.target.value)}>
                <option value="">{HomePage.chooseMaterial[lang]}</option>
                {materials.map((material) => (
                  <option key={material._id} value={material._id}>
                    {material.name}: {material.code}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </Form>
          {choosed.length > 0 && (
            <Form.Group controlId="selectedMaterials">
              <Form.Label>{HomePage.currentMaterials[lang]}</Form.Label>
              <ListGroup>
                {choosed.map((selectedMaterial) => (
                  <ListGroup.Item key={selectedMaterial._id}>
                    {selectedMaterial.name} : {selectedMaterial.code} -
                    <Button variant="danger" size="sm" onClick={() => handleRemoveMaterial(selectedMaterial._id)}>
                      {HomePage.delete[lang]}
                    </Button>
                  </ListGroup.Item>
                ))}
              </ListGroup>
            </Form.Group>
          )}
        </Modal.Body>

        <Modal.Footer>
          {message ? <Alert variant={message.case ? 'success' : 'danger'}>{message?.message}</Alert> : <></>}
          <Button variant="secondary" onClick={handleCloseAddMaterialModal}>
            {HomePage.close[lang]}
          </Button>
          {/* Other form fields and actions go here */}
          <Button variant="primary" type="submit" onClick={() => submiteMaterials()} >
            {HomePage.add[lang]}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ModalAddMaterial;
