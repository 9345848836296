import React from 'react'
import MessagesCont from '../message/messages';
import {useSelector} from 'react-redux';
function StudentMessages({user}) {
  const users = useSelector(mapStateToProps);
  return (
    
    <MessagesCont sent={user.sent} recived={user.recived} users={users}/>
  )
}
function mapStateToProps({users}){
  return users.teachers
}
export default StudentMessages