import React from 'react'
import BlueHeader from './blueHeader';
import Contain from '../styled-components/contain';

import './teachers.css'
import TeacherVideo from '../Home/teacherVideo.jsx';
import { useSelector, useDispatch } from 'react-redux';
import { Choose_Teacher } from "../../actions/booking";
import { useNavigate } from "react-router-dom";

function TeachersPage() {
  const navigate = useNavigate()
  const dispatch = useDispatch();

  const { teachers } = useSelector(getTeachers);
  const onClick = (teacher) => {
    try {
      dispatch(Choose_Teacher(teacher._id))
      navigate('/booking')
    } catch (err) {
      console.log(err)
    }
  }
  return (

    <div>
      <BlueHeader title='teachers' />

      {/* <img src='file:///E://projects//courses-web-app//server//uploads/download.png'/> */}

      <section className='teach-list'>
        {/* //    <TeacherVideo key={i} index={i} user={t} margin='0px 50px 0 50px '/> */}

        {teachers.length ? teachers.map((t, i) => <TeacherVideo onClick={onClick} key={i} index={i} user={t} />) :
          <p>No Teachers added......</p>
        }




      </section>
    </div>
  )
}
function getTeachers({ users }) {
  const teachers = users.teachers.length ? users.teachers.filter((t) => t.active == true) : []

  return { teachers: teachers }
}
export default TeachersPage