import React from 'react'
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { Inetialize_Teachers } from '../../actions/teachers';
// import Contain from '../styled-components/contain';
// import Col from '../styled-components/col';
// import axios from "axios";
import Whyus from './whyus.js'
// import URL_BASE from '../../../.env'
//import { Container } from 'react-bootstrap'
// import Header from './header'
import Statistics from './statistics.js';
import HeroSection from './heroSection'
// import TeacherVideo from './teacherVideo';
import TeachersHome from './teachers.js';
import "./home.css"
import Joinus from './join-us.js';
import StartLearning from './startLearning.js';
import { useLocation, useNavigate } from 'react-router-dom';
import StudyPrograms from '../publicPages/programs/subscription.js';
import SubscribeWithUs from './SubscribeWithUs.js';
import BookSession from './BookSession.js';
import { HomePage } from '../../translations/trans.js';
// require('dotenv').config()
function Home() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(Inetialize_Teachers())
  }, [])

  // const { teachers, lang } = useSelector(mapStateToProps)

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const paymentStatus = queryParams.get('paymentStatus');

    if (paymentStatus) {
      if (paymentStatus === 'SUCCESS') {
        navigate('/success'); // Redirect to the success page
      } else {
        navigate('/error'); // Redirect to the error page or handle other statuses
      }
    }
  }, [location, navigate]);

  const { lang, dir } = useSelector(({ language }) => {
    return { lang: language.lang, dir: language.dir };
  });


  return (
    <>


      <HeroSection />
      <Whyus />
      <Statistics />
      {
        // <TeachersHome teachers={teachers}/>
      }

      <div className='p-5'>
        <h2 style={{ color: "#211F54", fontWeight: "800" }} dir={dir}>{HomePage.studyProgram[lang]}</h2>
        <StudyPrograms showIt={false} />
      </div>

      <SubscribeWithUs />

      {/* <BookSession /> */}



      {/* <Joinus /> */}



      {/* <StartLearning lang={lang}/> */}
    </>


  )
}
// function mapStateToProps({ users, translation }) {// get all teachers from users
//   // const teachers = users?.teachers?.length ? users?.teachers?.filter((t) => t.active == true) : []
//   // const lang = translation.current;
//   const teachers=[];
//   const lang=[];
//   return {  }
// }
export default Home
