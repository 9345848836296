import React from 'react'
import { NavLink } from 'react-router-dom'

function NavButton({ name, to, bgColor, width, perfix, id }) {
  return (
    <>
      <NavLink className={`navButton ${perfix}`}
        style={({ isActive }) => ({
          color: isActive ? "red" : "#fff",
          opacity: isActive ? 1 : "",
          backgroundColor: bgColor,
          width: width
        })}
        id={id}
        to={to} >{name}</NavLink>

    </>
  )
}

export default NavButton