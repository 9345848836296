import { urlBase } from "./API";
import axios from "axios";
export const setResult = (data)=>{
    // console.log(data)
    return axios({
        method:"POST",
        url:`${urlBase}/api/set-result`,
        data:data,//{userID, testID, result, total, passed, questions:[]}
        withCredentials:true
    })
}
// Get Result By User by test

export const getResultByUserByTest = (data)=>{
    return axios({
        method:"POST",
        url:`${urlBase}/api/get-user-result`,
        data:data,//{userID,testID}
        withCredentials:true
    })

}
// get all results of a user
export const getResultByUser = (data)=>{
    return axios({
        method:"POST",
        url:`${urlBase}/api/get-user-results`,
        data:data,//{userID}
        withCredentials:true
    })

}
// get results of specific test id
export const getTestResults = (data)=>{
    return axios({
        method:"POST",
        url:`${urlBase}/api/get-test-results`,
        data:data,//{testID}
        withCredentials:true
    })

}