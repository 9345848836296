import { AUTH_LOGIN,SEND_MESSAGE } from "../actions/login&register";
import {EDIT_APPOINTMENT} from '../actions/booking';

export const loggedUser = (state = {}, action) => {
  switch (action.type) {
    case AUTH_LOGIN:
      return state={...action.user};
    case SEND_MESSAGE:
        return state = {...state,user:action.user}
    case EDIT_APPOINTMENT: 
       const appointments = state.user.appointments.map(a=>{
        if(a.id===action.bookingId){
          a.finished=parseInt(a.finished)+1;
          return a
        }
        else return a
       }) 
    default:
      return state;
  }
};
