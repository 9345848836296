import React, { useState, useRef } from "react";
import serializeForm from "form-serialize";
import {useNavigate} from 'react-router-dom';
import { useDispatch } from "react-redux";

import { 
    Register_Admin,
    Register_Teacher,
    Register_Student,
    intializeLogIn,
    logIn,
  } from "../../actions/login&register";
import Input from "./inputElement";

const LoginForm = ({ mode,setMode,type}) => {
    const formLogin = useRef();
    const formSignup = useRef();
    const dispatch = useDispatch();
   const navigate = useNavigate()
    const [message,setMessage] = useState()
    const signUp = (e) => {
      try {
        e.preventDefault();
        const data = serializeForm(formSignup.current, { hash: true });
        data.role =type;
        data.username=data.fullname
        dispatch(Register_Student(data));
        
        if (type === "select type") alert("please choose account type");
        else{
        if (type === "teacher") {
          dispatch(Register_Teacher(data));
          
        }
        if (type === "student") {
          dispatch(Register_Student(data));
        }
        if (type === "admin") {
          dispatch(Register_Admin(data));
        }
        setMode('login')
      }
      } catch (err) {
        console.log(err);
      }
    };

    //-------Login Function--------
    const logInFunc = (e) => {

      try {
        e.preventDefault();
        const data = serializeForm(formLogin.current, { hash: true });
        dispatch(logIn(data)).then(res=>{
          // console.log(res)
          setMessage('')
          if(res.auth ){
            if(res.user.role==='admin'){
              setMessage('login successfully')
              // navigate('/admin-dashboard')  
             navigate(`/user/${res.user._id}`)     

            }
            else{

              setMessage('login successfully')
             navigate(`/user/${res.user._id}`)     
            }
    }
          else{
  setMessage('password or username is not correct or username does not exist')
          }
        })
      } catch (err) {
        console.log(err);
      }
    };

 
    return (
      <div className='cont-log'>
        <div className='log-head-cont'>
    </div>
      <div>
        <div/>
        {mode === "login" && (
          <form ref={formLogin} onSubmit={logInFunc}>
            <div className="form-group form-group--login">
                
              <Input type="text" id="email" placeholder="email" />
              <Input type="password" id="password" placeholder="password"  />
            </div>
            <button className="button button--primary full-width" type="submit">
              login
            </button>
          </form>
        )}
        {mode === "signup" && ( 
          <form ref={formSignup} onSubmit={signUp}>
          
            <div className="form-block__input-wrapper">
               <div className="form-group form-group--signup">

                <Input type="text"     id="fullname"        placeholder="full name"      />
                {/* <Input type="text"     id="username"        placeholder="user name"      /> */}
                <Input type="phone"    id="phone"           placeholder="phone number"   />
                <Input type="email"    id="email"           placeholder="email"          />
                <Input type="password" id="password"        placeholder="password"       />
                <Input type="password" id="repeatpassword"  placeholder="repeat password"/>
              
              </div>
            </div>
            <button className="button button--primary full-width" type="submit">
              signUp
            </button>
          </form>
        )}

      </div>
      </div>
    );
  };
   export default LoginForm;