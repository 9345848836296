import React from 'react';
import { useLocation } from "react-router-dom";

// import backG from '../../assets/blue-header.png';
function BlueHeader({ title, subTitle, secondSubTitle }) {
  const style = {
    // display: 'block',
    // backgroundImage:'url(../../assets/blue-header.png)'
    color: "white",
    minHeight: "30vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  }
  const location = useLocation();
  // console.log(title.split(' ').join(''))
  return (
    <header className='blue-h' style={style}>
      <div>
        <h1 id={'bluetsb' + title.split(' ').join('')} style={{ color: "white" }}>
          {title ? title : 'Title'}
        </h1>

        <p id={'blue-subt'} style={{ color: "white" }}>{subTitle} &gt; {secondSubTitle}</p>
      </div>
    </header>
  )
}

export default BlueHeader