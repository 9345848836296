import React, { useState } from 'react'
import { Table } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { addHomeWork } from '../../utils/homeworkapi';
import { success } from '../../utils/Toast';
import axios from 'axios';
import { urlBase } from '../../utils/API';

const UploadHomeWork = ({ session, title }) => {
    const [show, setShow] = useState(false);
    const params = useParams();
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [file, setFile] = useState(null);

    const loggedUser = useSelector((state) => state.loggedUser)
    console.log(loggedUser)


    const uploadingFileLocally = (image) => {
        const formData = new FormData();
        formData.append("file", image)
        return axios({
            method: "POST",
            url: `${urlBase}/api/upload-file-locally?userId=${loggedUser?.user?._id}`,
            data: formData,
        })
    }

    const handleUploadHomeWork = async () => {
        try {
            const uploadRes = await uploadingFileLocally(file);
            console.log(uploadRes?.data?.data?.name)
            const res = await addHomeWork({
                classId: params?.classId,
                sessionId: session?._id,
                studentId: loggedUser?.user?._id,
                file: uploadRes?.data?.data?.name,
            })
            console.log(res)
            if (res?.data?.case) {
                success(res?.data?.message);
                handleClose();
            }
        } catch (e) {
            console.log(e)
        }
    }




    return (
        <div>
            <div>
                <button className='mx-3' onClick={handleShow} style={{ borderRadius: "10px", border: "none", outline: "none", background: "#283476", color: "white" }}>{title}</button>


                <Modal show={show} onHide={handleClose} centered className='py-5'>
                    <Modal.Body>
                        <input type="file" name="file" id="file" className='form-control'
                            onChange={(event) => { setFile(event.target.files[0]) }}
                        />
                    </Modal.Body>
                    <div className='m-auto text-center w-50' style={{ padding: "80px" }}>
                        <button onClick={handleUploadHomeWork} className='w-100' style={{ textAlign: "center", borderRadius: "10px", border: "none", outline: "none", background: "#283476", color: "white", padding: "10px" }}>رفع</button>
                    </div>
                </Modal>
            </div>
        </div>
    )
}

export default UploadHomeWork;