import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom';
import { MdAssignmentAdd } from "react-icons/md";
import { Set_Test_For as Set_Test } from '../../../actions/curriculums/view'
import { useDispatch } from 'react-redux';

function AddTestBtn({ type, testFor }) {
    const [url, setUrl] = useState('');

    useEffect(() => {
        const pathname = window.location.pathname.split('/');
        pathname.pop();
        const prevPath = pathname.join('/')
        setUrl(`${prevPath}/add-test`)
    }, [])

    const dispatch = useDispatch();

    const setTest = () => {
        dispatch(Set_Test({ type, testFor }))
    }
    return (
        <NavLink to={url} >
            <MdAssignmentAdd
                size={30}
                color='#293476'
                onClick={setTest}
            />
        </NavLink>
    )
}

export default AddTestBtn