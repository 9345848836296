import React, { useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import ReactPlayer from "react-player"
const ShowRecords = ({ title, urlVideo, downloadVideo }) => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handleDownlaod = () => {
        window.location = downloadVideo
    }
    return (
        <div>
            <span style={{ color: "black" }} onClick={handleShow}>
                {title}
            </span>

            <Modal show={show} onHide={handleClose} centered size='xl'>
                <Modal.Body>

                    <iframe src={urlVideo} width="100%" height="500px" title={`title`}></iframe>

                    <button onClick={handleDownlaod} className='btn mt-5' style={{ color: "white", background: "#4169E2" }} rel="noreferrer"  >
                        تنزيل فيديو
                    </button>
                </Modal.Body>
                {/* <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleClose}>
                        Save Changes
                    </Button>
                </Modal.Footer> */}
            </Modal>
        </div>
    )
}

export default ShowRecords
