import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { FaUsers } from "react-icons/fa";
import { Modal, Button, Table } from 'react-bootstrap';
import SendMessage from '../../message/sendMessage';
import UpdateSubscription from './updateSubscription';

function ClassStudents({ Class }) {
  const [show, setShow] = useState(false);
  const { students } = useSelector(state => {
    return {
      students: state.users.students,
    };
  });

  const [classStudents, setClassStudents] = useState([]);

  useEffect(() => {
    const IDS = Class.studentsIDS;
    const stds = students.filter(s => IDS.includes(s._id));
    setClassStudents(stds);
  }, [Class]);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <FaUsers onClick={handleShow} size={20} color="#001C45" />
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Class Students</Modal.Title>
          <UpdateSubscription cls={Class}/>
        </Modal.Header>
        <Modal.Body>
          <Table responsive striped bordered hover>
            <thead>
              <tr>
                <th>#</th>
                <th>الاسم</th>
                <th>البريد الالكترونى</th>
                <th>ارسال رسالة</th>
                {/* Add more headers if needed */}
              </tr>
            </thead>
            <tbody>
              {classStudents.map((student, index) => (
                <tr key={student._id}>
                  <td>{index + 1}</td>
                  <td>{student.name ? student.name : student.username}</td>
                  <td>{student.email}</td>
                  <td><SendMessage to={student._id}/></td>
                  {/* Add more cells if needed */}
                </tr>
              ))}
            </tbody>    
          </Table>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ClassStudents;
