import React, { useState } from 'react';
import { Modal, Button, Form, Alert } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { Create_Material } from '../../../actions/curriculums/materials';
import { BoxLoading } from 'react-loadingg';

function AddMaterial() {
  const [showModal, setShowModal] = useState(false);
  const [materialName, setMaterialName] = useState('');
  const [materialCode, setMaterialCode] = useState('');
  const [alertVariant, setAlertVariant] = useState('danger'); // State for alert variant
  const [alertMessage, setAlertMessage] = useState(null); // State for alert message
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setAlertMessage(null); // Clear alert message when closing modal
  };

  const handleCreateMaterial = async () => {
    try {
      setLoading(true);

      // Dispatch the create material action (await if asynchronous)
      const res = await dispatch(Create_Material({ name: materialName, code: materialCode }));

      // Optionally, you can reset the input fields after creating the material
      setMaterialName('');
      setMaterialCode('');

      // Determine the alert variant based on res.data.case
      setAlertVariant(res.data.case ? 'success' : 'danger');

      // Set the alert message
      setAlertMessage(res.data.message);
    } catch (err) {
      console.error(err.message);

      // Handle errors or provide user feedback as needed
      setAlertVariant('danger');
      setAlertMessage('Error creating material. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <h2>Add Material</h2>
      <Button variant="primary" onClick={handleOpenModal}>
        Add
      </Button>

      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Add Material</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {alertMessage && (
            <Alert variant={alertVariant} onClose={() => setAlertMessage(null)} dismissible>
              {alertMessage}
            </Alert>
          )}

          <Form>
            <Form.Group controlId="formMaterialName">
              <Form.Label>Material Name:</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter material name"
                value={materialName}
                onChange={(e) => setMaterialName(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="formMaterialCode">
              <Form.Label>Material Code:</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter material code"
                value={materialCode}
                onChange={(e) => setMaterialCode(e.target.value)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          {!loading ? (
            <>
          <Button variant="secondary" onClick={handleCloseModal}>
            Cancel
          </Button>
            <Button variant="primary" onClick={handleCreateMaterial}>
              Create Material
            </Button>
            </>
          ) : (
            <BoxLoading />
          )}
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default AddMaterial;
