import React from 'react'
import { Link } from 'react-router-dom'
import subscripeOne from "../../assets/images/subscripeSec1.png"
import subscripeTwo from "../../assets/images/subscripeSec2.png"
import subscripeThree from "../../assets/images/subscripeSec3.png"
import { useSelector } from 'react-redux'
import { HomePage } from '../../translations/trans'

const SubscribeWithUs = () => {
    const { lang, dir } = useSelector(({ language }) => {
        return { lang: language.lang, dir: language.dir };
    });
    return (
        <div className='public_bg' style={{ marginBottom: "200px", marginTop: "200px" }}>
            <div style={{ width: "90%", margin: "auto" }}>
                <div className='row p-5'>
                    <div className='col-lg-6 col-sm-12 col-md-12 col-12'>
                        <div className='d-flex justify-content-evenly align-items-center'>
                            <div>
                                <div>
                                    <img src={subscripeOne} alt="subc" style={{ marginTop: "-80px" }} className='imgSub w-100' />
                                </div>
                                <div> <img src={subscripeTwo} alt="subc" className='imgSub mt-5 w-100' /></div>
                            </div>
                            <div>
                                <img src={subscripeThree} alt="sub" className='imgSub w-100' />
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-6 col-sm-12 col-md-12 col-12'>
                        <div style={{ color: "white" }}>
                            <h3 className='mb-4'>{HomePage.subData[lang]}</h3>
                            <span>
                                {HomePage.subContent[lang]}
                            </span>

                            <div className='mt-5'>
                                <Link to="/register" className='btn mx-3' style={{ background: "white", borderRadius: "20px", color: "#1F3662" }}>{HomePage.registerNow[lang]}</Link>
                                <Link to="/login" className='btn mx-3' style={{ borderRadius: "20px", color: "white", border: "1px solid white" }}>{HomePage.regis[lang]}</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SubscribeWithUs
