import React from 'react';
import { Button, Card, Col, Container, Row } from 'react-bootstrap';
import BlueHeader from '../blueHeader';
import { useSelector } from 'react-redux';
import PackageCard from './packageCard';
import { HomePage } from '../../../translations/trans';

function StudyPrograms({ showIt }) {
  const { packages } = useSelector(({ packages }) => ({ packages }));
  const { lang, dir } = useSelector(({ language }) => {
    return { lang: language.lang, dir: language.dir };
  });

  return (
    <div dir={`${dir}`}>
      {
        showIt ? (
          <BlueHeader
            title={`${HomePage.studyProgram[lang]}`}
            subTitle={`${HomePage.home[lang]}`}
            secondSubTitle={`${HomePage.studyProgram[lang]}`}
          />
        ) : null
      }

      <Container fluid style={{ marginTop: '100px', marginBottom: '100px' }}>
        <Row>
          {packages.map((packageItem) => {
            if (packageItem.active === true) {
              return (
                <PackageCard key={packageItem._id} packageItem={packageItem} />
              );
            }
            return null; // If package is not active, don't render anything
          })}
        </Row>
      </Container>
    </div>
  );
}

export default StudyPrograms;
