import { GET_TEST, SET_RESULT, CHECK_STUDENT_PARTICIPATE } from '../actions/curriculums/test';

export const test = (state = {}, action) => {
  switch (action.type) {
    case GET_TEST:
      return state = action.test
    default: return state
  }
}
export const testResults = (state = {}, action) => {
  switch (action.type) {
    case SET_RESULT:
      return state = action.result
    default: return state
  }
}
export const checkTest = (state = {}, action) => {
  switch (action.type) {
    case CHECK_STUDENT_PARTICIPATE:
      // console.log(action.result)
      return state = action.result
    default: return state
  }
}
