import {getUrlbase} from '../utils/API'
export const INETIALIZE_URL_BASE='INETIALIZE_URL_BASE';
const inetializeUrlBase = (url)=>{
    return{
        type: INETIALIZE_URL_BASE,
        url
    }
}
export const Inetialize_Url_Base = ()=>{
    return(dispatch)=>{
        return getUrlbase().then(res=>dispatch(inetializeUrlBase(res.data)))
    }
}