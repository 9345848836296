import React, { useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import { Link, useParams } from 'react-router-dom';

const ModalShowSessions = ({ sessions, lesson }) => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <div>
            <div>
                <button className='btn mx-3' onClick={handleShow} style={{ background: "white", color: "#283476" }}>عرض الحصص</button>

                <Modal show={show} onHide={handleClose} centered size='xl'>
                    <Modal.Body>
                        {
                            sessions
                                ?.filter(session => session?.lesson?._id === lesson._id)
                                ?.map((session) => {
                                    return (
                                        session?.record?.map((rec) => {
                                            return (
                                                <div key={rec?.play_url}>
                                                    <iframe src={rec?.play_url} width="100%" height="500px" title="Video"></iframe>
                                                    <Link to={`${rec?.download_url}`} className='btn mt-5' style={{ color: "white", background: "#4169E2" }} rel="noreferrer">
                                                        تنزيل فيديو
                                                    </Link>
                                                </div>
                                            )
                                        })
                                    )
                                })
                        }
                    </Modal.Body>
                </Modal>
            </div>
        </div>
    )
}

export default ModalShowSessions;
