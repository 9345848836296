import {urlBase} from './API';
import axios from 'axios';
export const newPack = (data)=>{
    return axios({
      method:'POST',
      data:data,//{materials , price}
      //materials:[{id,lessons,all:true or false}]
      withCredentials:true,
      url:`${urlBase}/api/create/package`
    })
}

//  update package

export const updatePackage = (data)=>{
    return axios({
      method:'POST',
      url:`${urlBase}/api/update/package`,
      data:data,//{id,materials or active}
      withCredentials:true,
    })
}
// GET ALL PACKAGES
export const getAllPackages = ()=>{
    return axios({
      method:'get',
      url:`${urlBase}/api/get-all-packages`,
      responseType: "json",
    })
  }
  //GET PACKAGE BY ID
  export const getPackageById = (id)=>{
    return axios({
      method:'POST',
      url:`${urlBase}/api/get/package/by/id`,
      data:id,
      withCredentials:true
    })
  }
  // DELETE PACKAGE 
  export const deletePackage = (id) =>{
    return axios({
      method:"delete",
      url:`${urlBase}/api/delete/package`,
      data:{id},
      withCredentials:true
    })
  }
