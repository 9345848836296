import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Container, Row, Col, Button, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { FcFullTrash } from "react-icons/fc";
import { BiMessageDetail } from "react-icons/bi";
import { Delete_Student } from "../../actions/students.js";
import { Current_Product } from "../../actions/product.js";
import NewAccount from "./newAccount/newAccount.jsx";
import { urlBase } from "../../utils/API.js";
import NewRequest from "../curriculums/requests/newRequest.js";
function Students() {
  const [render, setRender] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const dispatch = useDispatch();
  const { students } = useSelector(mapStateToProps);

  console.log(students)

  useEffect(() => {
    // Do something on component mount or when render state changes
  }, [render]);

  const handleDeleteStudent = (studentId) => {
    dispatch(Delete_Student(studentId));
    setRender(!render);
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value.trim());
  };

  const filteredStudents = students?.filter(
    (student) =>
      (student?.username ?? "").toLowerCase().includes(searchTerm.toLowerCase()) ||
      (student?.email ?? "").toLowerCase().includes(searchTerm.toLowerCase()) ||
      (student?.phone ?? "").toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <Container fluid>
      <Row>
        <Col xs={12} md={8}>
          <NewAccount type="student" />
        </Col>
        <Col xs={12} md={4}>
          <Form>
            <Form.Group controlId="searchTerm">
              <Form.Control
                type="text"
                placeholder="Search by name, email, or phone"
                value={searchTerm}
                onChange={handleSearch}
              />
            </Form.Group>
          </Form>
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={8}>
          {filteredStudents.map((student, index) => (
            <StudentList key={index} student={student} onDelete={() => handleDeleteStudent(student._id)} />
          ))}
        </Col>
      </Row>
    </Container>
  );
}

function StudentList({ student, onDelete }) {

  const personalImage = student?.personalImage ? student?.personalImage : null;
  const imageLink = personalImage ? `${urlBase}/uploads/${personalImage}` : "";
  const dispatch = useDispatch();
  const { loggedUser } = useSelector(mapStateToProps);
  const navigate = useNavigate();

  const handleViewMessages = () => {
    dispatch(Current_Product(student));
    navigate(`/user/${loggedUser.user._id}/messages`);
  };

  return (
    <Row className="mb-3">
      <Col xs={2} md={1}>
        <img src={imageLink} alt='' className="img-fluid" />
      </Col>
      <Col xs={6} md={4}>
        <h5>{student?.username}</h5>
        <p>{student?.email}</p>
        <p>{student?.phone}</p>
        <p>{student?.date?.split("T")[0]}</p>
      </Col>
      <Col xs={4} md={2}>
        <Button style={{ width: '50px' }} variant="danger" onClick={onDelete}>
          <FcFullTrash size="20px" />
        </Button>
      </Col>
      <Col xs={4} md={2}>
        <Button style={{ width: '50px' }} variant="info" onClick={handleViewMessages}>
          <BiMessageDetail size="20px" />
        </Button>
      </Col>
      <Col xs={4} md={2}>
        <NewRequest
          userID={student?._id}
          valid={true}
        />
      </Col>
    </Row>
  );
}

function mapStateToProps({ users, loggedUser }) {
  const students = users?.students;

  return {
    students,
    loggedUser,
  };
}

export default Students;
