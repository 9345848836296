import { urlBase } from "./API";

import axios from "axios";

// create lesson

export const createLesson = (data)=>{
    return axios({
        method:"POST",
        url:`${urlBase}/api/new-lesson`,
        data:data,
        withCredentials:true
    })
}

// get lesson by id

export const getLessonById = (id)=>{
    return axios({
        method:"POST",
        url:`${urlBase}/api/get-lesson-by-id`,
        data:id, //object {_id:id}
        withCredentials:true
    })
}
// get lessons by IDS

export const getLessonsByIDS = (lessonsIDS)=>{
    return axios({
        method:"POST",
        url:`${urlBase}/api/get-lessons-by-ids`,
        data:lessonsIDS, //{lessonsIDS: [IDS]}
        withCredentials:true
    })
}
// GET LESSONS OF SPECIFIC MATERIAL BY CODE 
export const getLessonsOfSpecificMaterial = (code)=>{
    return axios({
        method:'POST',
        url:`${urlBase}/api/get-lseeons-by-code`,
        data:code,
        withCredentials:true
    })
}

// get all lessons
export const getAllLessons = ()=>{
    return axios({
        method:"POST",
        url:`${urlBase}/api/get-all-lessons`,
        data:null,
        withCredentials:true
    })
}

// get content urls
export const getContentUrls = (data)=>{
    return axios({
        method:"POST",
        url:`${urlBase}/api/get-content-urls`,
        data:data,
        withCredentials:true
    })
}
// delete lesson
export const deleteLesson = (id)=>{
    return axios({
        method:"DELETE",
        url:`${urlBase}/api/delete-lesson`,
        data:id,
        
    })
}


