import React, { useEffect, useState } from 'react';
import { Alert, Button, Modal } from 'react-bootstrap';
import { updateRequest } from '../../../utils/packagesRequests';
import {useSelector} from "react-redux";
import {toast} from "react-toastify"
function UpdateSubscription({cls}) {
  const users = cls?.studentsIDS ? cls?.studentsIDS : [];
  const packID = cls?.packID;

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [message, setMessage] = useState(null)

  useEffect(()=>{
    setTimeout(()=>{
      setMessage(null)
    },[5000])
  },[message])
  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleConfirmUpdate = async () => {
    try {
      if(!users.length){
        return toast.info("لايوجد طلاب فى هذا الفصل")
      }
     const res = await updateRequest({ users, packID });
     setMessage(res?.data);
     toast.success(res?.data?.message)
     if(res?.data?.case){
       setIsModalOpen(false);
     }
    } catch (error) {
      console.error("Failed to update subscription:", error);
      setMessage({case:false,message:error?.message})
      
    }
  };

  return (
    <>
      <Button variant="primary" onClick={handleOpenModal}>
        تحديث الاشتراك
      </Button>

      <Modal show={isModalOpen} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>تأكيد التحديث</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          هل أنت متأكد أنك تريد تحديث الاشتراك لهؤلاء المستخدمين؟
        </Modal.Body>
        <Modal.Footer>
         {message?<Alert variant={message?.case?"success":"danger"}>{message?.message}</Alert>:<></>}
          <Button variant="secondary" onClick={handleCloseModal}>
            إلغاء
          </Button>
          <Button variant="primary" onClick={handleConfirmUpdate}>
            تأكيد
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default UpdateSubscription;
