export const HomePage = {
    // NavBar
    home: {
        AR: 'الرئيسية',
        EN: 'Home',
    },
    studyProgram: {
        AR: "البرامج الدراسية ",
        EN: "Study Program"
    },
    aboutUs: {
        AR: "من نحن",
        EN: "About us"
    },
    resetPass:{
        AR:"اعادة تعيين كلمة السر",
        EN:"Reset Password"
    },
    // Hero Section Header
    heroText: {
        AR: "نوفر بيئة تعليمية متكاملة تدعم الطالب في كل خطوة، بدءًا من تخطيط الدروس وحتى تقييم أداء الطلاب، لضمان تقديم تعليم ذو جودة عالية ونتائج مميزة.",
        EN: "We provide an integrated learning environment that supports the student at every step, from lesson planning to evaluating student performance, to ensure high quality education and outstanding results."
    },
    heroSubText: {
        AR: " تتيح المنصة  الأدوات والموارد اللازمة لجعل تعليم التاريخ والجغرافيا أكثر تفاعلًا وفهمًا للطلاب. من خلال دمج التكنولوجيا الحديثة وأساليب التدريس الفعّالة، ",
        EN: " The platform provides the tools and resources needed to make History & Geography education more interactive and understanding for students. By integrating modern technology and effective teaching methods, "
    },

    startNow: {
        AR: "أبدء الان",
        EN: "Start Now"
    },
    seeMore: {
        AR: "شاهد المزيد",
        EN: "See More",
    },
    registerNow: {
        AR: "سجل الآن",
        EN: "Register"
    },
    logOut: {
        AR: "تسجيل الخروج",
        EN: "Log Out"
    },

    // learn abou platform
    learnPlatform: {
        AR: "لماذا أتعلم عبر المنصة ؟",
        EN: "Why am I learning through the platform?"
    },
    learnPlatCon: {
        AR: "لأنه نموذجٌ للتعليم الآمن والفعال والمتاح بأسعار معقولة، لجميع متعلمي اللغة، مثلك تمامًا.",
        EN: "Because it is a model of safe, effective and affordable education,"
    },
    platOne: {
        AR: "تعليم موثوق ",
        EN: "All-in-one platform"
    },
    platOneContent: {
        AR: "نلتزم بتقديم محتوى تعليمي ذو جودة عالية، معتمد على أسس علمية متينة وأساليب تدريس مبتكرة ",
        EN: "We provide a variety of educational tools, interactive lessons, and advanced tests covering all levels"
    },
    platTwo: {
        AR: "أسعار معقولة",
        EN: "Flexible schedule"
    },
    platContent: {
        AR: "نقدم خطط اشتراك مرنة تناسب مختلف الميزانيات عن بُعد يناسب أي ميزانية.ابدأ رحلتك نحو النجاح الأكاديمي",
        EN: "We coordinate learning schedules according to your schedule. Book lessons when you want to learn."
    },
    platThree: {
        AR: "جدول مرن",
        EN: "Affordable prices"
    },
    platThreeContent: {
        AR: "نُنسق مواعيد التعلّم حسب جدولك. احجز الدروس في الوقت الذي تريد التعلّم به.",
        EN: "We offer flexible subscription plans that suit different budgets remotely to suit any budget."
    },
    platFour: {
        AR: "منصة شاملة",
        EN: "Trusted teachers"
    },
    platFourContent: {
        AR: "نوفر مجموعة متنوعة من الأدوات التعليمية، الدروس التفاعلية، والاختبارات المتقدمة التي تغطي جميع المستويات",
        EN: "We are committed to providing high quality educational content, based on solid scientific foundations and innovative teaching methods"
    },

    statics: {
        AR: "إحصائيات",
        EN: "Statistics"
    },
    quiz: {
        AR: "أختبارات وكويزات",
        EN: "Quiz and Tests"
    },
    quizDes: {
        AR: "معتمدة",
        EN: "Certified"
    },
    highCollect: {
        AR: "مجاميع مرتفعه",
        EN: "High Scores"
    },
    highCollectDes: {
        AR: "محققة",
        EN: "Activated"
    },
    sessionMark: {
        AR: "حصص تجريبية",
        EN: "Trial Classes"
    },
    sessionMarkDes: {
        AR: "منجزة",
        EN: "Done"
    },
    // Subscripe With Us
    subData: {
        AR: "اشترك معنا وتميز في تحصيلك الدراسي",
        EN: "Subscribe with us and excel in your academic achievement"
    },
    subContent: {
        AR: "، ستجد الدعم الكامل الذي تحتاجه لتفوقك في مادة الفيزياء. استعد لرحلة تعليمية تفاعلية وممتعة تفتح لك أبواب النجاح وتجعلك في طليعة المتفوقين. لا تتردد في الانضمام وابدأ رحلتك نحو التميز اليوم!",
        EN: "you will find the full support you need to excel in physics. Get ready for an interactive and fun learning journey that opens doors to success and puts you at the forefront of excellence. Feel free to join and start your journey towards excellence today!"
    },
    regis: {
        AR: "اشترك الان",
        EN: "Login"
    },
    details: {
        AR: "التفاصيل",
        EN: "Details"
    },
    subscribeNow: {
        AR: "اشترك الان",
        EN: "subscribe Now"
    },

    // Book Session
    bookSession: {
        AR: "اختر جدولك واحجز حصصك التجريبية",
        EN: "Choose your schedule and book your trial sessions"
    },
    bookSessionCon: {
        AR: "كيف تعمل المنصة",
        EN: "How the platform works"
    },
    sessionOne: {
        AR: "اختر صفك المناسب",
        EN: "Choose your appropriate class",
    },
    sessionTwo: {
        AR: "حدد الوقت المناسب",
        EN: "Select the right time",
    },
    sessionThree: {
        AR: "أستمتع بتجربتك التعليمية",
        EN: "Enjoy your learning experience",
    },
    freeTrial: {
        AR: "تجربه مجانيه",
        EN: "Free Trial"
    },

    // Footer
    footerContent: {
        AR: `
            نهدف إلى رفع كفائة المُعلمين وتعزيز قدراتهم من عدة جوانب "مهارية ،علمية ،تربوية" كما نهدف ايضًا إلى تأهيلهم لسوق العمل وتزويد خبراتهم المهنية ومساعدتهم على مواكبة التطورات الرقمية المستمرة التي تطرأ على مجال التعليم


        `,
        EN: "Subscribe with us now and achieve excellence in your academic achievement, and you can through our website choose the teacher, the appropriate timing, book, subscribe to the course and all the information",
    },

    Abouttheplatform: {
        AR: "عن المنصه",
        EN: "About the platform"
    },
    quickLinks: {
        AR: "روابط سريعه",
        EN: "Quick Links"
    },
    privacy: {
        AR: "سياسة الخصوصيه والاستخدام",
        EN: "Privacy & Usage Policy"
    },
    contactUs: {
        AR: "تواصل معنا",
        EN: "contact us"
    },
    contactInformation: {
        AR: "معلومات التواصل",
        EN: "Contact Information"
    },

    // About
    teachers: {
        AR: "المعلمين",
        EN: "Teachers"
    },
    saraPlatform: {
        AR: "منصة المستشار التعليمية",
        EN: "Al-Mustshar Educational Platform"
    },
    future: {
        AR: "اصنع مستقبلك!",
        EN: "Create your future"
    },
    futureContent: {
        AR: "لتعليم مادتى التاريخ - الجغرافيا وبامكانكم عبر موقعنا الالكتروني اختيار الحصص والتوقيت المناسب والحجز والاشتراك بالحصص وجميع معلومات",
        EN: "For teaching History - Grography, you can choose the classes and suitable timing through our website, book and subscribe to the classes, and find all the information."
    },
    whyplatform: {
        AR: "لماذا منصة المستشار التعليمية",
        EN: "Why the Al-Mustshar educational platform"
    },
    whyContent: {
        AR: "لتعليم مادة الفيزياء وبامكانكم عبر موقعنا الالكتروني اختيار الحصص والتوقيت المناسب والحجز والاشتراك بالحصص وجميع معلومات",
        EN: "Why the AGA educational platform for teaching physics, and you can choose the classes, suitable timings, book, and subscribe to the sessions through our website, along with all the information."
    },
    lookDevelop: {
        AR: "نحن نبحث عن التطور الدائم",
        EN: " strive for development"
    },
    looktamiz: {
        AR: "نسعى دائما للتميز",
        EN: "excellence strive  "
    },
    looksuccess: {
        AR: "معنا نضمن لك النجاح كمعلم تربوى",
        EN: "outstanding education"
    },
    Specialprice: {
        AR: "اسعارنا مميزة",
        EN: "special prices "
    },
    work: {
        AR: "كيف تعمل؟",
        EN: " How does it work?"
    },
    workOne: {
        AR: "احصل على التفوق",
        EN: "Achieve excellence."
    },
    workOneContent: {
        AR: "اصنع مستقبلك",
        EN: "Create your future!"
    },
    workTwo: {
        AR: "اشترك في دورتك",
        EN: "Subscribe to your course"
    },
    workTwoContent: {
        AR: "قم بالأشتراك في الباقة و تعلم كل جديد",
        EN: "learn everything new"
    },
    workThree: {
        AR: "ابحث عن صفك",
        EN: "Find your class.",
    },
    workThreeContent: {
        AR: "ابحث عن المعلم المناسب لك و الذي تريده",
        EN: "Find the class for you "
    },
    // Privacy
    privacyContent: {
        AR: "سياسة الخصوصية –منصة المستشار التعليمية",
        EN: "Privacy & Usage Policy - Al-Mustshar"
    },
    privacyData: {
        AR: "الخصوصية وبيان سريّة المعلومات",
        EN: "Privacy and Confidentiality Statement"
    },
    privacyForIt: {
        AR: `
        نأخذ على محمل الجد خصوصية زوار موقعنا الإلكتروني وأمن أية معلومات شخصية قد يقدمها زوار الموقع. ونؤمن بحقك في معرفة نوع المعلومات التي يجمعها الموقع، وكيف يتم حمايتها واستخدامها، وتحت أية ظروف يتم الكشف عنها. ولهذا أعددنا سياسة الخصوصية هذه ونشرناها على موقعنا لكي تساعدكم في فهم طبيعة البيانات التي نقوم بتجميعها عنكم عند زيارتكم لموقعنا على شبكة الإنترنت وكيفية تعاملنا مع هذه البيانات.
        `,
        EN: "We take the privacy of our website visitors and the security of any personal information that visitors may provide very seriously. We believe in your right to know what types of information the site collects, how it is protected and used, and under what circumstances it may be disclosed. Therefore, we have prepared this privacy policy and published it on our site to help you understand the nature of the data we collect about you when you visit our website and how we handle this data."
    },

    browsePrivacy: {
        AR: "تصفح الموقع",
        EN: "Browse the site  "
    },

    browserPrivacy: {
        AR: "لا يهدف هذا الموقع إلى جمع بياناتك الشخصية أثناء تصفحك للموقع، وإنما سيتم فقط استخدام البيانات المقدمة من قبلك بمعرفتك ومحض إرادتك",
        EN: "This site does not aim to collect your personal data while you are browsing the site, but the data provided by you will only be used with your knowledge and of your own free will."
    },
    // contact Us

    Stayincontact: {
        AR: "ابقى على تواصل",
        EN: "Stay in contact"
    },
    helContactUs: {
        AR: "نحن هنا لمساعدتك .. لا تتردد في التواصل معنا",
        EN: "We are here to help you.. do not hesitate to contact us."
    },
    phone: {
        AR: "رقم الهاتف",
        EN: "Phone number"
    },
    email: {
        AR: "البريد الإلكتروني",
        EN: "Email"
    },
    follwo: {
        AR: "تابعنا علي",
        EN: "Follow us :"
    },

    // form conatct us
    leaveMessage: {
        AR: "اترك رسالة",
        EN: "Send Message"
    },
    contentLeave: {
        AR: "منصة Sara Physics التعليمية - طريقك نحو التميز والتفوق والإبداع، انتهز الفرصة!",
        EN: "Sara Physics Educational Platform - Your path to excellence, excellence and creativity, seize the opportunity"
    },
    nameForm: {
        AR: "الاسم",
        EN: "name"
    },
    message: {
        AR: "رساله",
        EN: "Message"
    },

    // Register Form
    forRegister: {
        AR: "تسجيل الدخول",
        EN: "login"
    },

    registerData: {
        AR: "تسجيل",
        EN: "Register"
    },

    loginRegisrer: {
        AR: "دخول",
        EN: "Login"
    },

    welcomeRegister: {
        AR: "مرحبا فى صفحة تسجيل الدخول",
        EN: "Welcome to the login page"
    },
    welcomeLogin: {
        AR: "مرحبا فى صفحة تسجيل الجديد",
        EN: "Welcome to the Register page"
    },
    pass: {
        AR: "كلمة السر",
        EN: "Password"
    },
    Newpass: {
        AR: "كلمة السر الجديده", 
        EN: "New Password"
    },
    oldPAss:{
        AR:"كلمة السر القديمه",
        EN:"Old Pass"
    },
    code:{
        AR:"ادخل الكود المرسل",
        EN:"Enter The Code"
    },
    enter: {
        AR: "دخول",
        EN: "Enter"
    },
    registerEnter: {
        AR: "تسجيل",
        EN: "Register"
    },

    repass: {
        AR: "اعد ادخال الرقم السري",
        EN: "Please Re Enter Your Password"
    },
    type: {
        AR: "النوع",
        EN: "Type"
    },
    male: {
        AR: "ذكر",
        EN: "Male"
    },
    female: {
        AR: "انثي",
        EN: "Female"
    },
    age: {
        AR: "العمر",
        EN: "Age"
    },

    // Dashboards

    dashboard: {
        AR: "لوحة التحكم",
        EN: "Dashboard"
    },
    subscriptions: {
        AR: "الاشتراكات",
        EN: "Subscriptions"
    },
    levels: {
        AR: "المستويات",
        EN: "Levels"
    },
    materials: {
        AR: "المواد",
        EN: "Materials"
    },

    programs: {
        AR: "البرامج",
        EN: "Programs"
    },
    classes: {
        AR: "الفصول",
        EN: "Classes"
    },
    profile: {
        AR: "حسابي",
        EN: "Profile"
    },
    students: {
        AR: "الطلاب",
        EN: "Students"
    },
    oldSubscriptions: {
        AR: "طلبات تم الرد عليها",
        EN: "Requests answered"
    },
    newSubscriptions: {
        AR: "طلبات جديده",
        EN: "New Subscriptions"
    },
    Requestswaiting: {
        AR: "طلبات في الانتظار",
        EN: "Requests are waiting"
    },
    package: {
        AR: "الباقه",
        EN: "Package"
    },
    price: {
        AR: "المبلغ",
        EN: "Price"
    },
    date: {
        AR: "التاريخ",
        EN: "Date"
    },
    action: {
        AR: "الاكشن",
        EN: "Action"
    },

    addLevel: {
        AR: "اضافة مستوي",
        EN: "Add Level"
    },
    addLevelName: {
        AR: "ادخل اسم المستوي",
        EN: "Add Level Name"
    },
    levelName: {
        AR: "اسم المستوي",
        EN: "Level Name"
    },
    noLevels: {
        AR: "لا يوجد مستويات",
        EN: "No Levels"
    },
    editLevel: {
        AR: "تعديل المستوي",
        EN: "Edit Level"
    },
    editLevelDetails: {
        AR: "اضف تفاصيل المستوي",
        EN: "Edit Level Details"
    },
    chooseLevelsystem: {
        AR: "اختر نظام المستوي",
        EN: "Choose Level System"
    },
    chooseMaterial: {
        AR: "اختر ماده",
        EN: "Choose Material"
    },
    currentMaterials: {
        AR: "المواد الحاليه",
        EN: "Current Materials"
    },
    AddMaterial: {
        AR: "اضافة الماده",
        EN: "Add Material"
    },
    material: {
        AR: "ماده",
        EN: "Material"
    },
    materialCode: {
        AR: "كود الماده",
        EN: "Material Code"
    },
    materialLesson: {
        AR: "دروس الماده",
        EN: "Material Lesson"
    },
    addLesson: {
        AR: "اضافة الدرس",
        EN: "Add Lesson"
    },
    addNewLesson: {
        AR: "اضافة درس جديد",
        EN: "Add New Lesson"
    },

    lessons: {
        AR: "الدروس",
        EN: "Lessons"
    },
    nameLesson: {
        AR: "اسم الدرس",
        EN: "Name Lesson"
    },
    uploadImage: {
        AR: "رفع الصوره",
        EN: "Upload Image"
    },
    uploadVideo: {
        AR: "رفع الفيديو",
        EN: "Upload Video"
    },
    uploadPdf: {
        AR: "رفع PDF",
        EN: "Upload PDF"
    },
    addTest: {
        AR: "اضافةأختبار",
        EN: "Add Test"
    },
    test: {
        AR: "اختبار",
        EN: "Test"
    },
    show: {
        AR: "عرض",
        EN: "Show"
    },
    edit: {
        AR: "تعديل",
        EN: "Edit"
    },
    delete: {
        AR: "حذف",
        EN: "Delete"
    },
    close: {
        AR: "اغلاق",
        EN: "Close"
    },
    add: {
        AR: "اضافه",
        EN: "Add"
    }
    ,

    // Programs Admin
    addNewProgram: {
        AR: "اضافة برنامج جديد",
        EN: "Add New Program"
    },
    packageName: {
        AR: "اسم الباقه",
        EN: "Package Name"
    },
    level: {
        AR: "المستوي",
        EN: "Level"
    },
    // price
    // materials
    chooseLesson: {
        AR: "اختر الدرس",
        EN: "Choose Lesson"
    },

    thePrograms: {
        AR: "البرامج الحاليه",
        EN: "Current Programs"
    },

    // Calsses
    addCalss: {
        AR: "اضافة الفصل",
        EN: "Add Class"
    },
    currentclasses: {
        AR: "الفصول الحاليه",
        EN: "Current Classes"
    },
    nonActiveClasses: {
        AR: "فصول غير مفعله",
        EN: "Classes are inactive"
    },

    class: {
        AR: "الفصل",
        EN: "class"
    },
    // studyProgram
    studentLength: {
        AR: "عدد الطلاب",
        EN: "Student Length"
    },
    sendMessage: {
        AR: "ارسل رساله",
        EN: "Sending Message"
    },
    addStudent: {
        AR: "اضف طلاب",
        EN: "Add Students"
    },
    // students
    createRoom: {
        AR: "انشاء غرفة اجتماع",
        EN: "Create Room"
    },
    showMeetings: {
        AR: "عرض الاجتماعات",
        EN: "Show Meetings"
    },

    sendReports:{
        AR:"ارسال التقارير ",
        EN:"Send Reports To Parents"
    },

    deactiveCalss: {
        AR: "تعطيل الفصل",
        EN: "Deactive Class"
    },

    address: {
        AR: "العنوان",
        EN: "Address"
    },
    content: {
        AR: "المحتوي",
        EN: "Content"
    },

    send: {
        AR: "ارسل",
        EN: "Send"
    },
    chooseStudents: {
        AR: "اختر طلاب",
        EN: "Choose Students"
    },
    chooseStuden: {
        AR: "اختر طالب",
        EN: "Choose Student"
    },

    // Report

    addreport: {
        AR: "اضافة التقارير",
        EN: "Add Report"
    },
    chooseTypeReport: {
        AR: "اختر نوع التقارير",
        EN: "Choose Type Report"
    },
    attendAndhomeWork: {
        AR: "غياب & واجب",
        EN: "Homework & attendence",
    },
    addRoom: {
        AR: "اضافة غرف",
        EN: "Add Room"
    },
    lessonName: {
        AR: "اسم الدرس",
        EN: "Name Lesson"
    },
    homeWork: {
        AR: "الواجب",
        EN: "Home Work"
    },
    dateAndTime: {
        AR: "التاريخ والوقت",
        EN: "Date & Time"
    },
    materialRecord: {
        AR: "تسجيل الماده",
        EN: "Material Record"
    },
    addNote: {
        AR: "اضف ملاحظه",
        EN: "Add Note"
    },
    AddHomeWork: {
        AR: "اضافة الواجب",
        EN: "Add HomeWork"
    },
    create: {
        AR: "انشاء",
        EN: "Create"
    },
    showSessions: {
        AR: "عرض الحصص",
        EN: "Show Session"
    },
    session: {
        AR: "حصه",
        EN: "session"
    },
    adminLink: {
        AR: "رابط غرفة الادمن",
        EN: "Zoom Admin Link"
    },
    adminStudents: {
        AR: "رابط غرفة الطالب",
        EN: "Zoom Student Link"
    },
    reports: {
        AR: "التقارير",
        EN: "Reports"
    },
    mettingStatus: {
        AR: "حالة الاجتماع",
        EN: "Meeting Status"
    },
    materialRecords: {
        AR: "تسجيلات الماده",
        EN: "Material Records"
    },
    addNewClass: {
        AR: "اضافة فصل جديد",
        EN: "Add New Class"
    },
    chooseLevel: {
        AR: "اختر المستوي",
        EN: "Choose Level"
    },
    chooseProgram: {
        AR: "اختر البرنامج",
        EN: "Choose Program"
    },
    forgetPass: {
        AR: "هل نسيت كلمة السر",
        EN: "Did You Forget Password"
    },
    phoneStudent: {
        AR: "رقم تلفون الطالب (بكود واتساب)",
        EN: "Student Phone (whatsapp code)"
    },
    phoneParent: {
        AR: "رقم تلفون ولي الامر (بكود واتساب)",
        EN: "Parent Phone (whatsapp code)"
    },
    nameSchool: {
        AR: "اسم المدرسه",
        EN: "Name School"
    },
    grade: {
        AR: "في اي صف تتواجد",
        EN: "What grade are you in?"
    },
    uploadHomeWork: {
        AR: "رفع الواجب",
        EN: "Upload Home Work"
    },
    country: {
        AR: "الدوله",
        EN: "Country"
    },
    cancel:{
        AR:"الغاء",
        EN:"Cancel"
    },
    detailsProgram:{
        AR:"تفاصيل البرنامج",
        EN:"Program Details "
    }
};

// AR:'',
// EN:'',
// FR:''