import React, { useEffect } from 'react'
import { useState } from 'react';
import { Table } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import ReportModal from './ReportModal';
import TableModal from './TableModal';
import { useSelector } from 'react-redux';
import GetLessonId from '../shared/GetLessonId';
import { HomePage } from '../../translations/trans';
const ShowMeetings = ({ packClass }) => {
    const [show, setShow] = useState(false);
    const [packageData, setPackageData] = useState(null);
    const [lesson, setLesson] = useState(null);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const { packages } = useSelector(({ packages }) => ({
        packages
    }));

    const fetchPackage = async () => {
        const pack = packages.find(p => p._id === packClass.packID);
        setPackageData(pack);
    };

    useEffect(() => {
        fetchPackage();
    }, [packClass, packages]);

    const { lang, dir } = useSelector(({ language }) => {
        return { lang: language.lang, dir: language.dir };
    });


    return (
        <div>
            <span onClick={handleShow} style={{ color: "#120C54" }}>
                {HomePage.showMeetings[lang]}
            </span>
            <Modal show={show} onHide={handleClose} centered>
                <Modal.Body>
                    <Table responsive>
                        <thead>
                            <tr>
                                <th>num</th>
                                <th>{HomePage.lessons[lang]}</th>
                                <th>{HomePage.showSessions[lang]}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>1</td>
                                <select style={{ border: "none" }} value={lesson} onChange={(e) => setLesson(e.target.value)}>
                                    <option>{HomePage.lessonName[lang]}</option>
                                    {packageData?.selectedLessons.map((lesson) => (
                                        <option key={lesson} value={lesson}>
                                            <GetLessonId lessonId={lesson} />
                                        </option>
                                    ))}
                                </select>
                                <td>
                                    {
                                        lesson !== null ? (
                                            <TableModal lessonId={lesson} classData={packClass} />
                                        ) : (
                                            <button className='btn' style={{ color: "white", background: "#4169E2" }} disabled>{HomePage.show[lang]}</button>
                                        )
                                    }

                                </td>
                            </tr>
                        </tbody>
                    </Table>
                </Modal.Body>
            </Modal>

            {/* Modal Show Table */}


        </div>
    )
}

export default ShowMeetings