import React, { useEffect, useState } from 'react';
import { getRequestPerUser } from '../../utils/packagesRequests';
import { Container, Row } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import {useDispatch} from "react-redux";
import ProgramCard from './programCard';

function Programs({ user }) {
  const [message, setMessage] = useState(null);
  const [programs, setPrograms] = useState([]);

  useEffect(() => {
    const fetchPrograms = async () => {
      try {
        const res = await getRequestPerUser({ userID: user._id });
        setMessage({ case: res.data.case, message: res.data.message });
        const validRequests = res.data.requests.filter(p=>p.valid);
        setPrograms(validRequests);
      } catch (err) {
        console.log(err.message);
        setMessage({ case: false, message: err.message });
      }
    };

    fetchPrograms();
  }, [user._id]);

  return (
    <Container style={containerStyle}>
      <h1 id='st-programs-title' style={titleStyle}>
        البرامج الدراسية
      </h1>
      {programs.length?programs.map((p,i) => (
        <ProgramCard key={i} program={p} user={user}/>
      )):
      <h2 style={{textAlign:'center',border:'1px solid',padding:'20px'}}>
        انت غير مشترك فى اى برامج دراسية بعد. <br/>
        <NavLink to='/subscription' style={{textDecoration:'none'}} >اشترك الان</NavLink>
        </h2>}
    </Container>
  );
}

// Styles
const containerStyle = {
  maxWidth: '800px',  // Adjust the maximum width for responsiveness
  margin: '0 auto',
};

const programCardStyle = {
  display: 'flex',
  flexDirection: 'column',  // Stack items vertically on small screens
  alignItems: 'center',
  backgroundColor: '#f5f5f5',
  padding: '20px',
  margin: '10px 0',
  borderRadius: '8px',
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
};

const cardContentStyle = {
  textAlign: 'center',
};

const programTitleStyle = {
  color: '#333',
  marginBottom: '10px',
};

const followLinkStyle = {
  color: '#fff',
  backgroundColor: '#283476',
  textDecoration: 'none',
  padding: '10px 15px',
  borderRadius: '5px',
  display: 'inline-block',
  transition: 'background-color 0.3s ease',
};

const titleStyle = {
  fontSize: '1.5rem',  // Adjust font size for smaller screens
};

export default Programs;
