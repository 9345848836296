import React from "react";
import { useRef } from "react";
import { useSelector } from "react-redux";
import { uploading } from "../../utils/API";
import "./upload.css";
import { useState } from "react";
import Progress from "./progress";
import axios from "axios";
// import VideoPlayer from "../videoPlayer";
import {urlBase} from '../../utils/API.js'
 
// const per = ()=>{
//     import image from `../../assets/${user.personalImage}`
// return image
// }
function UploadVideo() {
  const [file, setFile] = useState('');
  const [filename, setFilename] = useState('Choose File');
  const [uploadedFile, setUploadedFile] = useState({});
  const [message, setMessage] = useState('');
  
  const user = useSelector(mapStateToProps);

  const onChange = e => {
    setFile(e.target.files[0]);
    setFilename(e.target.files[0].name);
  };

  const [uploadPercentage, setUploadPercentage] = useState(0);

  //
  const fileUpload = async e => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('file', file);
    formData.append("type", "video");
    formData.append("email", user.email);
    formData.append("origin",window.location.origin)
    try {
      console.log(window.location.origin)
      await axios.post(`${urlBase}/api/upload`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        onUploadProgress: progressEvent => {
          setUploadPercentage(
            parseInt(
              Math.round((progressEvent.loaded * 100) / progressEvent.total)
            )
          );
        }
      }).then((res)=>{alert(res.data.link); 
        setUploadedFile( res.data.link)
      }
        )
      // Clear percentage
      setTimeout(() => setUploadPercentage(0), 5000);
      setMessage('File Uploaded');
      // setUser(user);
    } catch (err) {
      if (err.response.status === 500) {
        setMessage('There was a problem with the server');
      } else {
        setMessage(err.response.data.msg);
      }
      setUploadPercentage(0)
    }
  };
  return (
    <div className="container">
      <h1>File Upload</h1>
      <Progress percentage={uploadPercentage}/>
      <form id="form" onSubmit={fileUpload} encType="multipart/form-data">
        <div className="input-group">
          <label htmlFor="video">Personal Video : <span>.mp4, .avi, .webm</span></label>
          <input  id="video" type="file" onChange={onChange} required />
        </div>
        <button className="submit-btn" type="submit">
          Upload
        </button>
      </form>
    </div>
  );
}
function mapStateToProps({ loggedUser }) {
  return { ...loggedUser.user, auth: loggedUser.auth };
}
export default UploadVideo;
