import React, { useState,useEffect } from "react";
import { useSelector,useDispatch } from "react-redux";
import Col from "../styled-components/col";
import Contain from "../styled-components/contain";
import ListRow from "../styled-components/listRow";
import "./teacher.css";
import NewAccount from "./newAccount/newAccount";
// import {urlBase} from '../../utils/API'
import {useNavigate} from 'react-router-dom'

import {FaRegTrashAlt} from "react-icons/fa"
import {Delete_Teacher} from "../../actions/teachers.js"
import {BiMessageDetail} from 'react-icons/bi'
import {Current_Product} from '../../actions/product.js'; 

function TeachersList() {
  const[render,setRender]= useState(false)
  const dispatch = useDispatch()
  const { teachers } = useSelector(mapStateToProps);
  
  useEffect(()=>{

  },[render])
  return (
    <>
      <Contain perfix="teacher-admin-page" height='100%'>
        <Col fg='1' dir='column'>
        <NewAccount type='teacher'/>
        <Col width={"80%"} dir="column" align={"flex-start"}>
        <div className="scoll-overflow" style={{ width: "100%" }}>
          {teachers.map((t, i) => (
         <TeacherList key={i} item={t} icon={<FaRegTrashAlt size='20px' onClick={()=>{dispatch(Delete_Teacher(t._id)); setRender(!render)}}  style={{ 
         // top: "10px",
         right: "5px",
         cursor: "pointer",}}/>}/>))}
          </div>
        </Col>
        </Col>
      </Contain>
    </>
  );
}
function TeacherList({item,icon}){
  const {loggedUser} = useSelector(mapStateToProps);
  const navigate = useNavigate()
  const IM = item.personalImage?item.personalImage:''
  const dispatch = useDispatch();
    return(
      <>
      <ListRow
       one={IM}
       two={item.username}
        three={item.email} 
      four={item.date.split('T')[0]} 
      // five={<FaRegEdit/>}
      icon={
        <BiMessageDetail 
          style={{ 
            // top: "10px",
            // marginRight: "-15px",
            cursor: "pointer",}}
        onClick={()=>{
          dispatch(Current_Product(item));
          navigate(`/user/${loggedUser.user._id}/messages`)
        }}/>
      }
       five={icon}
       />
      </>
    )
}

// export function dispatchAction(dispatch,actionFunc){
//   dispatch
// }
function mapStateToProps({ users,loggedUser }) {

  const teachers = users.teachers;
  return {
    teachers,
    loggedUser
  };
}

export default TeachersList;
