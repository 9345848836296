import React from 'react'
import BlueHeader from './blueHeader'
import { AiFillYoutube, AiOutlineTwitter, AiFillTwitterSquare } from "react-icons/ai";
import { FaFacebookF, FaWhatsappSquare, FaInstagramSquare } from 'react-icons/fa'
import { useSelector } from 'react-redux';
import { HomePage } from '../../translations/trans.js';
const ContactUs = () => {
    const { lang, dir } = useSelector(({ language }) => {
        return { lang: language.lang, dir: language.dir };
    });
    return (   
        <div>
            <BlueHeader title={`${HomePage.contactUs[lang]}`} subTitle={`${HomePage.home[lang]}`} secondSubTitle={`${HomePage.contactUs[lang]}`} />
            <div style={{ width: "90%", margin: "auto" }} dir={`${dir}`}>
                <div className='row mt-5 justify-content-between align-items-center mb-5'>
                    <div className='col-lg-6 col-md-12 col-sm-12 col-12 py-5'>
                        <div>
                            <p style={{ color: "#283476" }}>{`${HomePage.Stayincontact[lang]}`}</p>
                            <h3 style={{ fontWeight: "800", color: "#283476" }}>{HomePage.helContactUs[lang]}</h3>
                        </div>
                        <div className='mt-5'>
                            <div className='mt-3'>
                                <p>{HomePage.phone[lang]}</p>
                                <span style={{ color: "#707070" }}>{}</span>
                            </div>
                            <div className='mt-3'>
                                <p>{HomePage.email[lang]}</p>
                                <span style={{ color: "#707070" }}></span>
                            </div>
                            <div>
                                <p style={{ color: "#1C3E77" }}>{HomePage.follwo[lang]}</p>
                                <div className="social-cont">
                                    <a className="sociall" href={"https://facebook.com"}>
                                        <FaWhatsappSquare
                                            color='#32d121'
                                            size="28px"
                                        />
                                    </a>

                                    <a className={"sociall "} href={"https://facebook.com"}>
                                        <FaInstagramSquare
                                            color='#ec4a64'
                                            size="28px" />
                                    </a>
                                    <a className={"sociall "} href={"https://facebook.com"}>
                                        <AiFillTwitterSquare
                                            color='#00b7ff'
                                            size="28px"
                                        />
                                    </a>



                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-6 col-md-12 col-sm-12 col-12 py-5'>
                        <div className='shadow' style={{ background: "white", borderRadius: "10px", padding: "20px" ,textAlign:`${dir === "rtl" ? "right":"left"}`}}>
                            <div style={{ textAlign: "center" }}>
                                <h4 style={{ color: "#283476" }}>{HomePage.leaveMessage[lang]}</h4>
                                <p>{HomePage.contentLeave[lang]}</p>
                            </div>
                            <div>
                                <form>
                                    <div style={{ marginTop: "30px" }}>
                                        <label className='mb-3'>{HomePage.nameForm[lang]}</label>
                                        <input type="text" name="name" className='form-control' style={{ background: "#F2F2F2" }} />
                                    </div>
                                    <div style={{ marginTop: "30px" }}>
                                        <label className='mb-3'>{HomePage.email[lang]}</label>
                                        <input type="text" name="name" className='form-control' style={{ background: "#F2F2F2" }} />
                                    </div>
                                    <div style={{ marginTop: "30px" }}>
                                        <label className='mb-3'>{HomePage.message[lang]}</label>
                                        <br />
                                        <textarea style={{ background: "#F2F2F2", resize: "none", width: "100%", borderRadius: "10px", border: "none" }} rows={"5"}>

                                        </textarea>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default ContactUs
