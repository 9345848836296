// import React, { useState } from 'react'
// import { Table } from 'react-bootstrap';
// import Modal from 'react-bootstrap/Modal';
// import { Link, useParams } from 'react-router-dom';
// import UploadHomeWork from './UploadHomeWork';
// import { useSelector } from 'react-redux';

// const HomeWork = ({ sessions, lesson }) => {
//     const [show, setShow] = useState(false);

//     const handleClose = () => setShow(false);
//     const handleShow = () => setShow(true);

//     console.log(sessions)

//     const loggedUser = useSelector((state)=>state.loggedUser)

//     return (
//         <div>
//             <div>
//                 <button className='btn mx-3' onClick={handleShow} style={{ background: "white", color: "#283476" }}>الواجب</button>

//                 <Modal show={show} onHide={handleClose} centered size='xl'>
//                     <Modal.Body>
//                         <Table>
//                         <thead>
//                             <th>اسم الحصه</th>
//                             <th>التنزيل</th>
//                             <th>الواجب</th>
//                         </thead>
//                         <tbody>
//                         {
//                             sessions
//                                 ?.filter(session => session?.lesson?._id === lesson._id)
//                                 ?.map((session) => {
//                                     return (
//                                         session?.record?.map((rec) => {
//                                             return (
                                        
//                                                         <tr>
//                                                             <td>{session?.note}</td>
//                                                             <td>
//                                                                 <Link to={`${rec?.download_url}`}>تنزيل الفيديو</Link>
//                                                             </td>
//                                                             <td>
//                                                             <UploadHomeWork session={session}/>
//                                                             </td>
//                                                         </tr>
                                                    
                                                
//                                             )
//                                         })
//                                     )
//                                 })
//                         }
//                         </tbody>
//                         </Table>
//                     </Modal.Body>
//                 </Modal>
//             </div>
//         </div>
//     )
// }

// export default HomeWork;


import React, { useState } from 'react';
import { Table } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { Link } from 'react-router-dom';
import UploadHomeWork from './UploadHomeWork';
import { useSelector } from 'react-redux';
const HomeWork = ({ sessions, lesson }) => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const loggedUser = useSelector((state)=>state.loggedUser)
    return (
        <div>
            <div>
                <button className='btn mx-3' onClick={handleShow} style={{ background: "white", color: "#283476" }}>الواجب</button>

                <Modal show={show} onHide={handleClose} centered size='xl'>
                    <Modal.Body>
                        <Table>
                            <thead>
                                <tr>
                                    <th>اسم الحصه</th>
                                    <th>التنزيل</th>
                                    <th>الواجب</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    sessions
                                        ?.filter(session => session?.lesson?._id === lesson._id)
                                        ?.map((session) => {
                                            return session?.record?.map((rec) => (
                                                <tr key={rec._id}>
                                                    <td>{session?.note}</td>
                                                    <td>
                                                        <Link to={`${rec?.download_url}`}>تنزيل الفيديو</Link>
                                                    </td>
                                                    <td>
                                                        {
                                                            session?.homeWork?.some(hw => hw?.student?._id === loggedUser.user?._id)
                                                                ? <UploadHomeWork session={session} title={"اعادة الرفع"}/>
                                                                : <UploadHomeWork session={session} title={"ارفع الواجب"} />
                                                        }
                                                    </td>
                                                </tr>
                                            ));
                                        })
                                }
                            </tbody>
                        </Table>
                    </Modal.Body>
                </Modal>
            </div>
        </div>
    )
}

export default HomeWork;
