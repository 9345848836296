import React from 'react'
import { BoxLoading } from 'react-loadingg';

function Loading() {
  return (
    <div className='loading'>
    <BoxLoading />
     </div>  )
}

export default Loading