import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Contain from "../styled-components/contain";
import Col from "../styled-components/col";
import Table2 from "../table/table2";
import { convertArrToObj } from "../globalFunc";
import SendMessage from "../message/sendMessage.js";
import OpenRoom from "../meeting/openRoom.js";
// import {Inetialize_Appointments} from '../../actions/booking.js'
import AddSession from "./addSession.js";
import "./filter.css";
import { filterByntype } from "../globalFunc";
function Courses() {
  const { user, usersarr, id, Appointments, products } =
    useSelector(mapStateToProps);
  const [currentUsers, setCurrentUsers] = useState(usersarr);
  const [currentAppointments, setCurrentAppointments] = useState(Appointments);
  //------------------------------------------------------------------------
  //-------------------------Student Data ----------------------------------
  // table header : name  ,course ,teacher,bookingDate , payment ,state, endDate
  const AmPm = (d) => {
    const dd = parseInt(d);
    if (dd > 12) {
      return d - 12 + " PM";
    } else {
      return d + " AM";
    }
  };
  const { lang } = useSelector(mapStateToProps);
  const days =
    lang === "en"
      ? [
        { name: "Sunday" },
        { name: "Monday" },
        { name: "Tuesday" },
        { name: "Wednesday" },
        { name: "Thursday" },
        { name: "Friday" },
        { name: "Saturday" },
      ]
      : [
        { name: "الاحد" },
        { name: "الاثنين" },
        { name: "الثلاثاء" },
        { name: "الاربعاء" },
        { name: "الخميس" },
        { name: "الجمعه" },
        { name: "السبت" },
      ];
  //
  const dispatch = useDispatch();

  const appointments = user && user.appointments && user.appointments.reverse();
  // console.log('app',appointments)
  const getDays = (ps, p) => {
    const dayArr = ps.filter((e) => e.name === p)[0];
    // console.log(dayArr);
    return dayArr.map((d) => <span>{days[parseInt(d)]}</span>);
  };
  //--------------------------------------
  //-------- Student - Teacher ------
  function getData(dataArr, teachers, id) {
    try {
      let output = {
        meeting: [],
        state: [],
        period: [],
        ended: [],
        time: [],
        days: [],
        payment: [],
      };
      dataArr.forEach((d, i) => {
        //each appoinment (d) has is called id in teacher and students schema
        //, and _id in admin schema
        output.ended = [...output.ended, d.finished];
        output.course = output.course
          ? [...output.course, d.packageName]
          : [d.packageName]; //get course name
        const [teacher] = teachers.filter((t) => t._id === d[id]); // teacher or student who this booking with
        const role = teacher.role;
        if (role === "teacher") {
          output.teacher = output.teacher
            ? [...output.teacher, teacher.username]
            : [teacher.username]; // get teacher
        }
        if (role === "student") {
          output.Add = output.Add
            ? [
              ...output.Add,
              <AddSession studentId={teacher._id} bookingId={d.id} />,
            ]
            : [<AddSession studentId={teacher._id} bookingId={d.id} />];
          // console.log('rol')
          output.student = output.student
            ? [...output.student, teacher.username]
            : [teacher.username]; // get teacher
        }
        output.meeting = [
          ...output.meeting,
          d.state ? <OpenRoom roomId={d.roomId} userId={user._id} /> : "ended",
        ];
        output.time = [...output.time, AmPm(new Date(d.startDate).getHours())];
        // output.days=[...output.days,getDays(products,d.packageName)]
        output.start = output.start
          ? [...output.start, d.startDate.split("T")[0]]
          : [d.startDate.split("T")[0]]; //get startDate
        output.end = output.end
          ? [...output.end, d.endDate.split("T")[0]]
          : [d.endDate.split("T")[0]];
        output.payment = [...output.payment, d.price + " RSA"]; //get course name
        output.state = [...output.state, d.state ? "progress" : "ended"]; //get course name
        output.message = output.message
          ? [
            ...output.message,
            <SendMessage type={user.role} sender={user._id} to={d[id]} />,
          ]
          : [<SendMessage type={user.role} sender={user._id} to={d[id]} />];
        output.days = [
          ...output.days,
          d.days.map((d) => days[d].name).join(", "),
        ];
        output.period = [...output.period, d.period];
      });
      return output;
    } catch (err) {
      console.log(err);
    }
  }
  //----------------------------------------------------------------------------
  // -----------------------Admin Data ---------------------------------------
  function getAppointments(dataArr, users) {
    try {
      const students = convertArrToObj(users.students, "_id");
      const teachers = convertArrToObj(users.teachers, "_id");
      let output = {
        course: [],
        student: [],
        teacher: [],
        state: [],
        payment: [],
        days: [],
        disscount: [],
        disscountCode: [],
        finished: [],
        period: [],
      };
      dataArr.forEach((d, i) => {
        const student = students[d.studentId];
        const teacher = teachers[d.teacherId];
        if (!teacher) return;
        if (!student) return;
        output.course = output.course
          ? [...output.course, d.packageName]
          : [d.packageName]; //get course name
        output.student = output.student
          ? [...output.student, student.username]
          : [student.username]; // get student
        output.teacher = output.teacher
          ? [...output.teacher, teacher.username]
          : [teacher.username]; // get student
        output.start = output.start
          ? [...output.start, d.startDate.split("T")[0]]
          : [d.startDate.split("T")[0]]; //get startDate
        output.end = output.end
          ? [...output.end, d.endDate.split("T")[0]]
          : [d.endDate.split("T")[0]];
        output.payment = output.payment
          ? [...output.payment, d.price]
          : [d.price]; //get course name
        output.state = [...output.state, d.state ? "progress" : "ended"]; //get course name
        output.disscount = [...output.disscount, d.disscount];
        output.disscountCode = [...output.disscountCode, d.discountCard];
        output.finished = [...output.finished, d.finished];
        output.days = [
          ...output.days,
          d.days.map((d) => days[d].name).join(", "),
        ];
        output.period = [...output.period, d.period];
      });
      // console.log("datArr", output);
      return output;
    } catch (err) {
      console.log(err);
    }
  }
  //----------------------------------------------------------------------------
  // console.log(getAppointments(appointments,usersarr,id))
  //-----------------------handel check box----------
  useEffect(() => { }, [currentUsers, currentAppointments]);
  const [filterOption, setFilterOption] = useState("student"); // type
  const onChange = (event) => {
    const value = event.target.value.trim();
    const type = filterOption;
    if ((type === "student") & (value !== "")) {
      const newStudents = filterByntype(
        value,
        currentUsers.students,
        "username"
      );
      console.log(newStudents);
      const newUsers = {
        teachers: currentUsers.teachers,
        students: newStudents,
      };
      setCurrentUsers(newUsers);
    }
    if ((type === "teacher") & (value !== "")) {
      const newTeachers = filterByntype(value, usersarr.teachers, "username");
      const newUsers = { teachers: newTeachers, students: usersarr.students };
      setCurrentUsers(newUsers);
    }
    if ((type === "code") & (value !== "")) {
      console.log(type);
      const newCurrentApp = filterByntype(value, Appointments, "discountCard");
      setCurrentAppointments(newCurrentApp);
    }
    if (value.trim() === "") {
      setCurrentUsers(usersarr);
      setCurrentAppointments(Appointments);
    }
  };
  const [selectedCheckbox, setSelectedCheckbox] = useState("stfilter");

  const handleCheckboxChange = (event) => {
    setSelectedCheckbox(event.target.id);
    setFilterOption(event.target.value);
  };

  return (
    <>
      {user.role === "admin" && (
        <div width="100%" className="filterAdmin">
          <label id="filterid">Filter bookings</label>
          <input type="text" onChange={(event) => onChange(event)} />
          <label>
            <input
              type="checkbox"
              checked={selectedCheckbox === "stfilter"}
              onChange={handleCheckboxChange}
              name="student"
              value="student"
              id="stfilter"
            />
            student
          </label>
          <label>
            <input
              type="checkbox"
              checked={selectedCheckbox === "tefilter"}
              onChange={handleCheckboxChange}
              name="teacher"
              value="teacher"
              id="tefilter"
            />
            teacher
          </label>
          <label>
            <input
              type="checkbox"
              checked={selectedCheckbox === "disfilter"}
              onChange={handleCheckboxChange}
              name="disscountCode"
              value="code"
              id="disfilter"
            />
            disscount code
          </label>
        </div>
      )}
      <Contain>
        <Col fg="0" width="95%">
          {user.role === "admin" && (
            <Table2
              title="appointments"
              data={getAppointments(currentAppointments, currentUsers)}
              colWidth="50px"
              font="14px"
            />
          )}
          {user.role === "teacher" && (
            <Table2
              title="appointments"
              data={getData(appointments, usersarr, id)}
              colWidth="50px"
              font="14px"
            />
          )}
          {user.role === "student" && (
            <Table2
              title="appointments"
              data={getData(appointments, usersarr, id)}
              colWidth="50px"
              font="14px"
            />
          )}
        </Col>
      </Contain>
    </>
  );
}
function mapStateToProps({
  loggedUser,
  users,
  appointments,
  products,
  translation,
}) {
  const lang = translation.current;
  const user = loggedUser.user;
  let usersarr = [];
  var id;
  const Appointments = appointments.sort((a, b) => {
    const d1 = new Date(a.bookingDate);
    const d2 = new Date(b.bookingDate);
    // console.log(d1-d2)
    return d2 - d1;
  });
  // console.log('sort',Appointments);
  if (user.role === "student") {
    usersarr = users.teachers;
    id = "teacherId";
  } else if (user.role === "teacher") {
    usersarr = users.students;
    id = "studentId";
  } else if (user.role === "admin") {
    usersarr = users;
  }

  // console.log("jkn", appointments);
  return {
    user,
    usersarr,
    id,
    Appointments,
    products,
    lang,
  };
}
export default Courses;
