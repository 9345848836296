import { CHOOSED_TEACHER, CHOOSE_PACKAGE,BOOKING_RESULT,INETIALIZE_APPOINTMENTS,EDIT_APPOINTMENT } from "../actions/booking";


export const booking = (state={teacher:'',start:{},package:{},booking:{}},action)=>{
    switch(action.type){
       case CHOOSED_TEACHER:
        return state={...state, teacher:action.teacher}
       case CHOOSE_PACKAGE:
        return state={...state,package:action.packag}
       
       case BOOKING_RESULT:

        return state.booking={...state,...action.details}
        default: return state
    }
} 
export const appointments = (state=[],action)=>{
     switch(action.type){
        case INETIALIZE_APPOINTMENTS:
            return state=action.data
        case EDIT_APPOINTMENT:
        return state=state.map(ss=>{
            if(ss._id===action.bookingId){
                let s=ss;
                // ss.finished=parseInt(ss.finished)+1;
                const period = parseInt(s.period);
        const finished = parseInt(s.finished);
        if(period===finished+1){
          s.finished=parseInt(s.finished)+1;
          s.state=false;
        }
        else if(period===finished){
          s.state=false;
        }
        else{
          s.finished=parseInt(s.finished)+1;

        }
            return s
            }
            else{return ss}
        }
        )
        default: return state
     }
}