import { combineReducers } from "redux";
import { loggedUser } from "./login";
import { QS } from "./questions";
import {test,testResults,checkTest} from './test'
import { general } from "./general";
import { users, admins } from "./register";
import {products} from "./products"
import { booking,appointments } from "./booking";
import {schedual} from "./schedual";
import {urlBase} from "./urlBase";
import {payment} from './payment';
import {meeting} from './etcMeeting';
import {translation} from './translation'
import {levels} from './levels'
import {materials} from './materials';
import {packages} from './packages';
import {view} from './view'
import {buyRequests} from './buyRequests'
import {classes} from './class'
import { language } from "./language";
export default combineReducers({
  loggedUser,
  users,
  admins,
  appointments,
  products,
  schedual,
  booking,
  meeting,
  translation,
  general,
  urlBase,
  payment,
  QS,
  test,
  testResults,
  checkTest,
  levels,
  materials,
  view,
  packages,
  buyRequests,
  classes,
  language

});
