import React, { useEffect, useState } from 'react'
import { FaUsers } from "react-icons/fa";
import { Modal, Button, Table } from 'react-bootstrap';
import { FaReply } from 'react-icons/fa';
import ChooseReport from './ChooseReport';
import { useSelector } from 'react-redux';
import SendMessage from '../message/sendMessage';
import { getStudentsByIDS, sendReport } from '../../utils/API';
import { HomePage } from '../../translations/trans';
import UpdateSubscription from '../Admin/classControl/updateSubscription';

const ShowUsers = ({ Class }) => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [showReport, setShowReport] = useState(false);
    const handleCloseReport = () => setShowReport(false);
    const handleShowReport = (id) => {
        setShowReport(true)
        handleClose();
    }





    const { students } = useSelector(state => {
        return {
            students: state.users.students,
        };
    });

    const [classStudents, setClassStudents] = useState([]);
    console.log(Class)

    useEffect(() => {
        const IDS = Class.studentsIDS;
        const stds = students?.filter(s => IDS.includes(s?._id));
        setClassStudents(stds);
    }, [Class]);
    // useEffect(() => {
    //     const fetchStudents = async () => {
    //         try {
    //             const IDS = Class.studentsIDS;
    //             const response = await getStudentsByIDS({ IDS: IDS });
    //             console.log(response);
    //             setClassStudents(response.data.students);
    //         } catch (error) {
    //             console.error("Failed to fetch students:", error);
    //         }
    //     };

    //     if (Class && Class.studentsIDS) {
    //         fetchStudents();
    //     }
    // }, [Class]);

    const { lang, dir } = useSelector(({ language }) => {
        return { lang: language.lang, dir: language.dir };
    });

    return (
        <div>
            {/* Modal Student */}
            <FaUsers onClick={handleShow} size={20} color="#001C45" />
            <Modal show={show} onHide={handleClose} size="lg" centered>
                <Modal.Header>
                    <UpdateSubscription cls={Class} />
                </Modal.Header>
                <Modal.Body>
                    <Table responsive>
                        <thead>
                            <tr>
                                <th>Num</th>
                                <th>{HomePage.nameForm[lang]}</th>
                                <th>{HomePage.email[lang]}</th>
                                <th>{HomePage.sendMessage[lang]}</th>
                                <th>{HomePage.addreport[lang]}</th>
                                <th>{HomePage.delete[lang]}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                classStudents.map((student, index) => {
                                    return (
                                        <tr key={student._id}>
                                            <td>{index + 1}</td>
                                            <td>{student.name ? student.name : student.username}</td>
                                            <td>{student.email}</td>
                                            <td><SendMessage to={student._id} /></td>
                                            <td>

                                                <ChooseReport classId={Class._id} studentId={student._id} />
                                            </td>
                                            <td>
                                                <button className='btn' style={{ backgroundColor: "#D43B43" }}>
                                                    {HomePage.delete[lang]}
                                                </button>
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </Table>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default ShowUsers

