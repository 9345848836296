import React from 'react'
import './whyus.css';
import v1 from '../../assets/images/why1-icon.png';
import v2 from '../../assets/images/why2-icon.png';
import v3 from '../../assets/images/why3-icon.png';
import v4 from '../../assets/images/why4-icon.png';
import { useSelector } from 'react-redux';
import { HomePage } from '../../translations/trans';

function Whyus() {

  const { lang, dir } = useSelector(({ language }) => {
    return { lang: language.lang, dir: language.dir };
  });
  const icons = [{ icon: v1, title: `${HomePage.platOne[lang]}`, dis: `${HomePage.platOneContent[lang]}` },
  { icon: v2, title: `${HomePage.platTwo[lang]}`, dis: `${HomePage.platContent[lang]}` },
  { icon: v4, title: `${HomePage.platThree[lang]}`, dis: `${HomePage.platThreeContent[lang]}` },

  { icon: v3, title: `${HomePage.platFour[lang]}`, dis: `${HomePage.platFourContent[lang]}` }
  ]
  return (
    <section className='why-us '>
      <div className='why-us-rect'>
        <h1 style={{ fontWeight: "900", color: "#1E2D40", fontFamily: "Tajawal" }}>
          {HomePage.learnPlatform[lang]}
        </h1>
        <p id='why2'>
          {HomePage.learnPlatCon[lang]}
        </p>
        <div className='why-us-icons '>
          {
            icons.map((c, i) => {
              return (
                <div className='why-icon-item' style={{ minHeight: "300px" }} key={i}>
                  <div style={{ width: "90%", margin: "auto", height: "150px" }}>
                    <img style={{ objectFit: "cover", height: "100px" }} src={c.icon} alt="imageIcon" />
                  </div>
                  <h4 id={'whyh4' + i} style={{ color: "#1E2D40", marginTop: "10px" }}>{c.title}</h4>
                  <span id={'whyspan' + i}>{c.dis}</span>
                </div>
              )
            })
          }
        </div>
      </div>
    </section>
  )
}

export default Whyus