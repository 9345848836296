import React,{useState,useEffect} from 'react'
import Modal from '../modal/modal';
import {useDispatch} from 'react-redux';
import {Edit_Appointment} from '../../actions/booking';
function AddSession({studentId, bookingId,teacherId}) {
    const [open ,setOpen] = useState(false);
    const [message ,setMessage] =useState('');
    const dispatch = useDispatch();
    
    const AddSessionWindow = ()=>{
        try{
        // call add session API
        dispatch(Edit_Appointment(bookingId,studentId,teacherId))
        .then(res=>{
if(res.status===200){setMessage('session added successfully')};
if(res.status===208){setMessage(res.err)}
if(res.status===500){setMessage('server error')}
        })
        }catch(e){
            console.log(e)
            setMessage(e.message)
        }
    }
  return (
    <>
<div>
    <button onClick={()=>setOpen(true)}  className='meeting-navigation'>

        Add +

    </button>
</div>
    <Modal open={open} width={'60vw'} height='30vh' onClose={()=>{setOpen(false)}}>
    <div className='' >
<p id='adse1'>
    Do you like to add a session?
</p>
<p>{message}</p>
<button onClick={AddSessionWindow} id='adse2'>
Ok
</button>
    </div>
    </Modal>
    </>
  )
}

export default AddSession