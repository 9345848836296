import axios from "axios"
import { urlBase } from "./API"

// Api For Check Out (supscripe to package)
export const subscripeToPackage = (data)=>{
    return axios({
        method:"POST",
        url:`${urlBase}/api/checkout`,
        data:data 
        // {
        //     "currency": "EGP", // EGP or USD      default EGP
        //     "redirectMethod": "get", // get or post    default get
        //     "websiteRedirect": "https://www.google.com",   // default https://www.google.com
        //     "packageId": "66c77a68e5eb1e00633c49f3", // req
        //     "userId": "66c77a7ae5eb1e00633c49fe", // req
        //     "brandColor": "rgba(0, 0, 163, 1)",  // color of the form  default  rgba(163, 0, 0, 1)
        //     "display": "en" // how to display   ar | en  
        // }
    })
}