import {Appointment,getAppointments,editAppointment} from '../utils/productApi'

export const CHOOSED_TEACHER = 'CHOOSED_TEACHER'
const chooseTeacher = (teacher)=>{
    return {
        type:CHOOSED_TEACHER,
        teacher
    }
}
export const Choose_Teacher=(teacher)=>{
    return (dispatch)=>{
        return dispatch(chooseTeacher(teacher))
    }
}

export const CHOOSE_PACKAGE= 'CHOOSE_PACKAGE'
const choosePackage = (packag)=>{
    return {
        type:CHOOSE_PACKAGE,
        packag
    }
}
export const Choose_Package=(packag)=>{
    return (dispatch)=>{
        return dispatch(choosePackage(packag))
    }
}
//
export const BOOKING_RESULT = 'BOOKING_RESULT'
const bookingResult = (details)=>{
    return{
        type:BOOKING_RESULT,
        details
    }
}
export const Booking_Results=(details)=>{
    // console.log('action',details)
    return (dispatch)=>{
        return dispatch(bookingResult(details))
    }
}
//confirm appointment
export const APPOINTMENT_CONFIRM = 'APPOINTMENT_CONFIRM';
const appointmentConfirm = (data)=>{
    return{
        type:APPOINTMENT_CONFIRM,
        data
    }
}
export const Appointment_Confirm=(data)=>{
    return (dispatch)=>{
        return Appointment(data).then(res=>{
            // console.log(res.data)
        })
    }
}
export const INETIALIZE_APPOINTMENTS = 'INETIALIZE_APPOINTMENTS';
const inetializeAppointments=(data)=>{
    return{
        type:INETIALIZE_APPOINTMENTS,
        data
    }
}
export const Inetialize_Appointments = ()=>{
    return (dispatch)=>getAppointments().then(res=>dispatch(inetializeAppointments(res.data)))
}
//edit appointment

export const EDIT_APPOINTMENT = 'EDIT_APPOINTMENT';

const edit_Appointment =(bookingId,studentId,teacherId)=>{
    return {
type:EDIT_APPOINTMENT,
bookingId,
studentId,
teacherId
    }
}
export const Edit_Appointment = (bookingId,studentId,teacherId)=>{
return dispatch=>{
 return editAppointment({bookingId,studentId,teacherId})
 .then(res=>{
    if(res.status===200){
        dispatch(edit_Appointment(bookingId,studentId,teacherId));
    }
    // console.log(res)
    return res
 })

}
}