
import React from 'react';
import BlueHeader from './blueHeader';
import Footer from '../Home/footer.jsx';
import Contain from '../styled-components/contain.jsx';
import Col from '../styled-components/col.jsx';
import './privacy.css';
import Header from '../Home/header.jsx';
import { useSelector } from 'react-redux';
import { HomePage } from '../../translations/trans.js';

function Privacy() {
  const { lang, dir } = useSelector(({ language }) => {
    return { lang: language.lang, dir: language.dir };
  });

  const textAlignStyle = { textAlign: dir === 'rtl' ? 'right' : 'left' };

  return (
    <>
      <Header />
      <BlueHeader title={HomePage.privacy[lang]} subTitle={HomePage.home[lang]} secondSubTitle={HomePage.privacy[lang]} />
      <Contain perfix='privacy-cont'>
        <Col perfix='privacy-sub-cont' width='80%' dir='column' align='start'>
          <div style={{  padding: "50px", textAlign: `${dir === "rtl" ? 'right' : 'left'}` }}>
            <h2 id='prv1'>{HomePage.privacyContent[lang]}</h2>
            <h3 id='prv2' className='mt-5'>{HomePage.privacyData[lang]}</h3>
            <p id='prv3' className='mt-3' style={{ color: "#6B6B6B" }}>
              {HomePage.privacyForIt[lang]}
            </p>
            <h4 className='mt-5'>{HomePage.browsePrivacy[lang]}</h4>
            <p className='mt-3' style={{ color: "#6B6B6B" }}>
              {HomePage.browserPrivacy[lang]}
            </p>
          </div>
        </Col>
      </Contain>
      <Footer />
    </>
  );
}

export default Privacy;
