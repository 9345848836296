import {getStudents,delStudent} from "../utils/API"
export const INETIALIZE_STUDENTS = 'INETIALIZE_STUDENTS'
export const UPDATE_STUDENTS = 'UPDATE_STUDENTS'
export const DELETE_STUDENTS = 'DELETE_STUDENTS'

const inetializeStudents = (students)=>{
    return {
        type: INETIALIZE_STUDENTS,
        students
    }
}
export const Inetialize_Students =()=>{
    return(dispatch)=>{
        return getStudents().then((res)=>dispatch(inetializeStudents(res.data.data))
        )
    }
}
export const DELETE_STUDENT = "DELETE_STUDENT";
const deleteStudent=(id)=>{
return{
    type:DELETE_STUDENT,
    id
}
}
export const Delete_Student=(id)=>{
  return (dispatch)=>{
    return delStudent(id).then(res=>{
        dispatch(deleteStudent(id))
        alert(res.data)
    })
  }
}