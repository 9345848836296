import React from 'react'
import imageSuccess from "../../assets/success.png"
import Footer from '../Home/footer'
import Header from '../Home/header'
import { Link } from 'react-router-dom'
const Success = () => {
    return (
        <div>
            <Header/>
            <div className='text-center'>
                <img src={imageSuccess} alt="success" />
                <p style={{ color: "#0E356E" }}>تم الاشتراك بنجاح</p>

                <div>
                    <Link to="/subscription" className='btn' style={{ background: "#15345E", color: "white", textAlign: "center" }}>الاشتراكات</Link>
                    <br/>
                    <Link to="/" className='btn mt-3 mb-5' style={{ border: "0.5px solid #15345E", color: "black" }}>الذهاب الي الصفحه الرئيسيه</Link>
                </div>
            </div>
            <Footer/>
        </div>
    )
}

export default Success
