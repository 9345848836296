import React, { useState } from 'react';
import { Table } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { Link } from 'react-router-dom';
import UploadHomeWork from './UploadHomeWork';
import { useSelector } from 'react-redux';
const ShowLiveSessions = ({ sessions, lesson }) => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const loggedUser = useSelector((state) => state.loggedUser)
    return (
        <div>
            <div>
                <button className='btn mx-3' onClick={handleShow} style={{ background: "white", color: "#283476" }}>الحصص الايف</button>

                <Modal show={show} onHide={handleClose} centered size='xl' className='text-center'>
                    <h3>يجب دخول الحصه الايف من خلال الايميل المسجل علي المنصه</h3>
                    <Modal.Body>
                        <Table>
                            <thead>
                                <tr>
                                    <th>اسم الحصه</th>
                                    <th>دخول</th>
                                </tr>
                            </thead>
                            <tbody>
                                
                                {
                                    sessions
                                        ?.filter(session => session?.meetingEnd === false)
                                        ?.map((session) => {
                                            return (
                                                <tr key={session._id}>
                                                    <td>{session?.note}</td>
                                                    <td>
                                                        <Link to={`${session?.joinLink}`}>دخول الي الاجتماع</Link>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                }
                            </tbody>
                        </Table>
                    </Modal.Body>
                </Modal>
            </div>
        </div>
    )
}

export default ShowLiveSessions;