import React from "react";
import "./heroSection.css";
import { NavLink } from "react-router-dom";
// import Hero from '../../assets/hero.png';
import { useSelector } from "react-redux";
// import ltrBack from '../../assets/hero.png';
// import rtlBack from '../../assets/Mr-ahmed.png';
import {Hero_Section_Image} from  "../../constants";
import { hero_section_title, second_hero_section_title } from "../../constants";
import { HomePage } from "../../translations/trans";

function HeroSection() {
  const { current } = useSelector(direction);
  const { lang, dir } = useSelector(({ language }) => {
    return { lang: language.lang, dir: language.dir };
  });


  const loggedUser = useSelector((state)=>state.loggedUser)

  return (
    <div className="hero-sec hero_Section " dir={`${dir}`}>
      <div className="row justify-content-between align-items-center">
        <div className="left-hero col-lg-6 col-md-12 col-sm-12 col-12">
          <h1>
            {hero_section_title}
          </h1>
          <p className="hero-para2" id="hero2" >
            {HomePage.heroText[lang]}
          </p>
          <p className="hero-para3" id="hero2" >
            {/* {second_hero_section_title} */}
            {HomePage.heroSubText[lang]}
          </p>
          <div className="hero-btns d-flex">
            {
              loggedUser?.auth === true ? (
              <NavLink id="hero3" to="/subscription" className="login-register-btn header-call-us btn mx-3">

              {HomePage.startNow[lang]}
            </NavLink>
            ):(
            <NavLink id="hero3" to="/login" className="login-register-btn header-call-us btn mx-3">

              {HomePage.startNow[lang]}
            </NavLink>
            )
            }
            <NavLink id="hero3" to="/login" className="btn   header-call-us" style={{ border: "1px solid #1F3662", color: "#1F3662" }}>

              {HomePage.seeMore[lang]}
            </NavLink>
          </div>
        </div>
        <div className="col-lg-6 col-md-12 col-sm-12 col-12 ">
          <div className=" text-center m-auto">
            <img src={Hero_Section_Image} alt="imageSara" style={{ objectFit: "cover", width: "100%" }} />
          </div>

        </div>
      </div>
    </div>
  );
}

function direction({ translation }) {
  return { current: translation.current };
}
export default HeroSection;
