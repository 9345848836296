import React from 'react'

function VideoPlayer({link,width,border,height}) {
   const style={
        width:width?width:'100%',
        border:border?`${border} solid #333`:'0',
        objectFit: "contain",
        height:height
    }
  return (
    <section>
    <video style={style}  autoPlay  loop>
        <source src={link} type="video/mp4" />
        {/* <source src={link} type="video/ogg" />  */}
        Your browser does not support the video tag.
      </video>
      </section>
  )
}

export default VideoPlayer