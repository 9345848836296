import React from 'react'
import { NavLink } from 'react-router-dom'
import { IoArrowBackCircleOutline } from "react-icons/io5";

function NavigationBar() {
  return (
    <div className='navigation-bar'>
      <NavLink to={-1} className={'navigator-btn'}>
        <IoArrowBackCircleOutline color='#fff' size={40}/>
      </NavLink>
    </div>
  )
}

export default NavigationBar