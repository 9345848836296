import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Change_Class_Active_State } from '../../../actions/class';
import { Modal, Button, Alert } from 'react-bootstrap';
import { HomePage } from '../../../translations/trans';

function DeactivateClass({ classId }) {
    const [showModal, setShowModal] = useState(false);
    const [response, setResponse] = useState(null);
    const dispatch = useDispatch();

    const handleDeactivate = async () => {
        try {
            const res = await dispatch(Change_Class_Active_State({ id: classId, active: false }));
            setResponse(res.data.case);
        } catch (error) {
            console.error('Error:', error);
        }
        // setShowModal(false);
    };

    const handleClose = () => {
        setShowModal(false);
        setResponse(null);
    };

    const handleShow = () => setShowModal(true);

    const { lang, dir } = useSelector(({ language }) => {
        return { lang: language.lang, dir: language.dir };
    });


    return (
        <div>
            {/* <Button variant="danger" >
                تعطيل
            </Button> */}
            <button className='btn' onClick={handleShow} style={{ backgroundColor: "#D43B43" }}>
                {HomePage.delete[lang]}
            </button>

            <Modal show={showModal} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirmation</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure you want to deactivate this class?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button variant="danger" onClick={handleDeactivate}>
                        Deactivate
                    </Button>
                </Modal.Footer>
            </Modal>

            {response && (
                <Alert variant={response ? 'success' : 'danger'} onClose={() => setResponse(null)} dismissible>
                    {response ? 'Class deactivated successfully' : 'Failed to deactivate class'}
                </Alert>
            )}
        </div>
    );
}

export default DeactivateClass;
