import React from 'react'
import { Outlet } from 'react-router-dom'
import Footer from './Home/footer'
import Header from './Home/header'
// import Col from './styled-components/col'
// import Contain from './styled-components/contain'
import GetText from './translation/getText'
import Chat from "./chat"
function Dashboard() {
  return (
    <>
    {/* <Contain perfix='header-cont'> */}

    <GetText>
    <div className='dash'>
    <Header menuColor={'#fff'}/>
   <Chat/>
   <Outlet/>
    </div>
   {/* </Contain> */}
   <Footer/> 
   </GetText>
    </>
  )
}

export default Dashboard