import { getTestsByIds, checkStudentParticipationTest } from "../../utils/tests";

export const GET_TEST = 'GET_TEST';
export const CHECK_STUDENT_PARTICIPATE = 'CHECK_STUDENT_PARTICIPATE';

const getTest = (test) => {
    return {
        type: GET_TEST,
        test
    }
}

export const Get_Test = (testIDS) => {
    return dispatch => getTestsByIds({ testIDS }).then((res) => {
        if (res.data.case) {
            dispatch(getTest(res.data.tests[0]))
        }
        return res
    })
}

export const SET_RESULT = "SET_RESULT";
const setResult = (result) => {
    return {
        type: SET_RESULT,
        result
    }
}
export const Set_Result = (result) => {
    return dispatch => dispatch(setResult(result))
}


const checkTest = (result) => {
    return {
        type: CHECK_STUDENT_PARTICIPATE,
        result
    }
}



export const CheckParticipate = (data) => {
    return dispatch => checkStudentParticipationTest(data).then((res) => {
        if (res.data.case) {
            dispatch(checkTest(res.data))
        }else{
            // console.log(res)
        }
        return res
    })
}