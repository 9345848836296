import React from 'react'
import './statistics.css'
import { useSelector } from "react-redux";
import { statisticsImage as com } from '../../constants.js'
import s1 from '../../assets/stat1.png'
import s2 from '../../assets/stat2.png'
import s3 from '../../assets/stat3.png'
import Bar from '../../assets/Bar chart line.png'
import staticImage from '../../assets/images/staticImage.png'
import { HomePage } from '../../translations/trans.js';
function Statistics() {

  const { current } = useSelector(direction);

  const style = {
    position: 'absolute',
    right: current === 'en' && '0',
    left: current === 'ar' && '0',
    top: '-150px',
    transform: current === 'ar' && 'scaleX(-1)',
  }

  const { lang, dir } = useSelector(({ language }) => {
    return { lang: language.lang, dir: language.dir };
  });


  const icons = [{ icon: s1, title: `${HomePage.quiz[lang]}`, dis: `${HomePage.quizDes[lang]}` },
  { icon: s2, title: `${HomePage.highCollect[lang]}`, dis: `${HomePage.highCollectDes[lang]}` },
  { icon: s3, title: `${HomePage.sessionMark[lang]}`, dis: `${HomePage.sessionMarkDes[lang]}` },
  ]

  return (
    <section className=' public_bg' style={{ marginTop: "120px", }}>
      <div className='px-3'>
        <div className='row'>
          <div className="col-lg-6 col-md-6 col-sm-12 col-12" style={{ color: "white" }}>
            <div className='w-100'>
              <div className='chart-bar mb-5'>
                <img src={Bar} alt='olp-chart' />
                <span id='statistics1'>
                  {HomePage.statics[lang]}
                </span>
              </div>
              <div className='statistics-icons'>
                {icons.map((c, i) => <div key={i} className='icon-item'>
                  <img src={c.icon} width='50px' alt="imageData" />
                  <h5 id={'stath' + i}>
                    {c.title}
                  </h5>
                  <span id={'statsp' + i}>
                    {c.dis}
                  </span>
                </div>)}
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-12 col-12">
            <div className='text-center m-auto'>
              <img src={staticImage} alt="data" className='w-100 py-' />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
function direction({ translation }) {
  return { current: translation.current };
}
export default Statistics
