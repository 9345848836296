import React from "react";
import "./table.css";
import GetText from '../translation/getText'
function Table2({ data, title, colWidth, font, color }) {
  console.log(data);
  data = data ? data : {};
  const keys = data && Object.keys(data);
  return (
    <GetText>
      <div className="table-cont">
        {keys.length?<table border="1" width="1000px">
          <caption className="caption">{title ? title : "Table Title"}</caption>
          <thead className='table-head' height="40vh">
            <tr>
              {keys.length &&
                keys.map((k, i) => (
                  <td key={i}>
                 <b> <span id={k.split(' ').join('')}>{k}</span></b>
                  </td>
                ))}
            </tr>
          </thead>
          <tbody>
            {keys.length &&
              data[keys[0]].map((d, i) => (
                <tr key={i} height="50vh">
                  {keys.map((k, j) => (
                    <td
                      className="table-cell"
                      key={j}
                      style={{ width: colWidth, fontSize: font, color: color }}
                    >
                      {data[keys[j]][i]}
                    </td>
                  ))}
                </tr>
              ))}
          </tbody>
        </table>:<h5>No Appointments Yet.</h5>}
      </div>
    </GetText>
  );
}

export default Table2;
