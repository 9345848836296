import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Form, Button, Alert, Container, Row } from 'react-bootstrap'; // Assuming you're using Bootstrap for the modal
import { New_Class } from '../../../actions/class';
import { HomePage } from '../../../translations/trans';

function AddClass() {
    const dispatch = useDispatch();
    const { levels,packages } = useSelector(({ levels,packages }) => ({ levels,packages }));
    const [showModal, setShowModal] = useState(false);
    const [formData, setFormData] = useState({ name: '', levelId: '',packID:'' });
    const [response, setResponse] = useState(null);
    const [packs, setPacks] = useState([])
    // const [packID,setPackID] = useState(null)

    const handleInputChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };
    
    useEffect(()=>{
       const currentPacks= packages.filter(p=>p.levelId===formData.levelId);
       setPacks(currentPacks)
    },[formData.levelId])

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const res = await dispatch(New_Class(formData));
            setResponse(res.data);
        } catch (error) {
            console.error('Error:', error);
            setResponse({ case: false, message:error.message });
        }
        // setShowModal(false);
    };
    const { lang, dir } = useSelector(({ language }) => {
        return { lang: language.lang, dir: language.dir };
    });
    return (
        <Container>
        <Row>
            <Button variant="primary" onClick={() => setShowModal(true)}>{HomePage.addCalss[lang]}</Button>
            <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>{HomePage.addNewClass[lang]}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleSubmit}>
                        <Form.Group controlId="formClassName">
                            <Form.Label>{HomePage.nameForm[lang]}</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter class name"
                                name="name"
                                value={formData.name}
                                onChange={handleInputChange}
                            />
                        </Form.Group>
                        <Form.Group controlId="formLevel">
                            <Form.Label>{HomePage.chooseLevel[lang]} </Form.Label>
                            <Form.Control
                                as="select"
                                name="levelId"
                                value={formData.levelId}
                                onChange={handleInputChange}
                            >
                                <option value="">{HomePage.chooseLevel[lang]}</option>
                                {levels.map((level) => (
                                    <option key={level._id} value={level._id}>{level.name}</option>
                                ))}
                            </Form.Control>
                        </Form.Group>
                        <Form.Group controlId="formLevel">
                            <Form.Label>اختر البرنامج </Form.Label>
                            <Form.Control
                                as="select"
                                name="packID"
                                // value={formData.packID}
                                onChange={handleInputChange}
                            >
                                <option value="">{HomePage.chooseProgram[lang]}</option>
                                {formData.levelId&&packs.map((p) =>
                                   <option key={p._id} value={p._id}>{p.name}</option>
                                  )}

                            </Form.Control>
                        </Form.Group>
                        <Button variant="primary" type="submit">
                            حفظ
                        </Button>
                    </Form>
                    {response && (
                        <Alert variant={response.case  ? 'success' : 'danger'}>
                            {response.message}
                        </Alert>
                    )}
                </Modal.Body>
            </Modal>
        </Row>
        </Container>
    );
}

export default AddClass;
