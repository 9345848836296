
import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { deleteSession } from '../../utils/sessions';
import { success } from '../../utils/Toast';

const ModalDeleteSession = ({ show, handleClose, sessionId, classId, onDelete }) => {

    const handleDelete = async () => {
        try {
            await deleteSession({
                classId: classId,
                sessionId: sessionId,
            });

            success("تم حذف الجلسة بنجاح");
            onDelete(sessionId); // Trigger the filter operation
            handleClose(); // Close the modal
        } catch (e) {
            console.error(e);
            // Handle the error if needed
        }
    };

    return (
        <Modal show={show} onHide={handleClose} centered>
            <Modal.Body>
                <h5>هل أنت متأكد أنك تريد حذف هذه الجلسة؟</h5>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    إلغاء
                </Button>
                <Button variant="danger" onClick={handleDelete}>
                    حذف
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ModalDeleteSession;
