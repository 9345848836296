import { deletePackage, getAllPackages, newPack, updatePackage } from '../utils/packages.js'
export const GET_ALL_PACKAGES = "GET_ALL_PACKAGES";
const getallPackages = (packages) => {
  return {
    type: GET_ALL_PACKAGES,
    packages
  }
}

export const Get_All_Packages = () => {
  return dispatch => getAllPackages().then(res => {
    if (res.data.case) {
      dispatch(getallPackages(res.data.packages));
    }
    return res
  })
}
// CREATE NEW PACKAGE 

export const CREATE_NEW_PACKAGE = "CREATE_NEW_PACKAGE";
const create_newPackage = (pack) => {
  return {
    type: CREATE_NEW_PACKAGE,
    pack
  }
}
export const Create_New_Package = (pack) => {
  return dispatch => newPack(pack).then(res => {
    if (res.data.case) {
      dispatch(create_newPackage(res.data.pack))
    }
    return res
  })
}

// UPDATE PACKAGE

export const UPDATE_PACKAGE = "UPDATE_PACKAGE";
const update_package = (pack) => {
  return {
    type: UPDATE_PACKAGE,
    pack
  }
}
export const Update_Package = (data) => {
  try {
    return dispatch => updatePackage(data).then(res => {
      if (res.data.case) {
        dispatch(update_package(res.data.pack))
      }
      return res
    })
  }
  catch (err) {
    alert(err.message)
  }
}
// delete package
export const DELETE_PACKAGE = "DELETE_PACKAGE";
const delete_package = (id) => {
  return {
    type: DELETE_PACKAGE,
    id
  }
}
export const Delete_Package = ({ id }) => {
  return dispatch => deletePackage(id).then(res => {
    if (res.data.case) {
      dispatch(delete_package(id))
    }
    return ReadableStream
  })
}