import React, { useEffect, useState } from 'react';
import { Modal, Button, Alert } from 'react-bootstrap';
import axios from 'axios';
import { urlBase } from '../../../utils/API';
import { createRequest } from '../../../utils/packagesRequests';
import { useLocation, useNavigate } from 'react-router-dom';
import { Wallet_Number } from '../../../constants';
import fawry from "../../../assets/fawry.png"
import visa from "../../../assets/visa.png"
import card from "../../../assets/masterCard.png"
import { subscripeToPackage } from '../../../utils/checkout';
import { HomePage } from '../../../translations/trans';
import { useSelector } from 'react-redux';
import { ErrorMsg } from '../../../utils/Toast';

function Subscripe({ packData, user }) {
  const [showModal, setShowModal] = useState(false);
  const [image, setImage] = useState(null);
  const [message, setMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleClose = () => setShowModal(false);

  const handleShow = () => {
    if (!user.auth) {
      alert('يجب تسجيل الدخول قبل الاشتراك');
      navigate('/login')
    }
    else {
      setShowModal(true);
    }
  }
  console.log(user)
  console.log(packData)
  const handleImageChange = (e) => setImage(e.target.files[0]);

  // const handleSubmit = async () => {
  //   try {
  //     if (!image) return alert('برجاء ارفاق صورة لرسالة او ايصال الدفع ');
  //     setLoading(true)
  //     const formData = new FormData();
  //     formData.append("file", image);
  //     const uploadingResponse = await axios.post(`${urlBase}/api/upload-file-locally`, formData);

  //     const request = {
  //       packData,
  //       userID: user?.user._id,
  //       packID: packData._id,
  //       receipt: uploadingResponse.data.data
  //     };

  //     const res = await createRequest(request);
  //     setLoading(false)
  //     setMessage({ case: res.data.case, message: res.data.message });
  //     alert(res.data.message);
  //     handleClose();
  //   } catch (err) {
  //     console.log(err.message);
  //     setLoading(false)
  //     setMessage({ case: false, message: err.message });
  //   }
  // };


  // const location = useLocation();


  const handleSubmit = async () => {
    try {
      const res = await subscripeToPackage({
        currency: "EGP",
        redirectMethod: "get",
        websiteRedirect: window.location.origin,
        packageId: packData._id,
        userId: user?.user._id,
        brandColor: "rgba(0, 0, 163, 1)",
        display: "en"
      })

      console.log(res)

      if (res?.data?.case) {
        window.location.href = res?.data?.hppUrl;
      }
    } catch (e) {
      console.log(e)
      ErrorMsg(e?.response?.data?.message)
    }
  }


  useEffect(() => {
    setTimeout(() => {
      setMessage(null)
    }, 5000)
  }, [message])
  const { lang, dir } = useSelector(({ language }) => {
    return { lang: language.lang, dir: language.dir };
  });
  return (
    <div>
      <button className="login-register-btn header-call-us btn " onClick={handleShow}>
        {HomePage.subscribeNow[lang]}
      </button>


      <Modal show={showModal} onHide={handleClose} centered size='lg'>
        <Modal.Header>
          <Modal.Title>{packData?.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* <Modal.Title>
            برجاء دفع مبلغ {packData.price} ج.م على محفظة الرقم الموضح
            ثم قم برفع صورة من ايصال او رسالة الدفع
          </Modal.Title>
          <Modal.Dialog dir={'ltr'} style={{ color: 'green', fontWeight: '900', textAlign: 'center' }}>
            {Wallet_Number}
          </Modal.Dialog>
          <label for="fileInput" className="custom-file-upload"> اختر صورة ايصال او رسالة الدفع</label><br />
          <input type="file" id="fileInput" onChange={handleImageChange} placeholder='ارفع ايصال الدفع' />
          {image && <img src={URL.createObjectURL(image)} alt="Chosen" style={{ display: 'block', marginTop: '10px', maxWidth: '80%' }} />} */}
          <div className='row justify-content-between align-items-center'>
            <div className='col-lg-4 col-md-6 col-sm-12 col-12 '>
              <h3>بوابة الدفع</h3>
              <p>اختر من وسائل الدفع</p>
              <img src={fawry} alt="ImagedA" className='imagePayMent' />
              <img src={visa} alt="ImageDA" className='imagePayMent' />
              <img src={card} alt="ImageDA" className='imagePayMent' />
            </div>
            <div className='col-lg-7 col-md-6 col-sm-12 col-12'>
              <p>الحساب النهائي</p>
              <h3>{packData.price} جنيه مصري</h3>
            </div>
          </div>

        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            {HomePage.cancel[lang]}
          </Button>
          {!loading ? <Button variant="primary" onClick={handleSubmit}>
            اشترك
          </Button> : <span>جارى الطلب ... </span>}
        </Modal.Footer>
      </Modal>

      {message && (
        <Alert variant={message.case ? 'success' : 'danger'} onClose={() => setMessage(null)} dismissible>
          <Alert.Heading>{message.case ? 'Success' : 'Error'}</Alert.Heading>
          <p>{message.message}</p>
        </Alert>
      )}
    </div>
  );
}

export default Subscripe;
