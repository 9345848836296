import React from 'react'
import AddLevel from './AddLevelComponent'
import Alllevels from './AlllevelsComponent'

function LevelsMainPage() {
  return (
    <div>
        <br/>
        <br/>
<AddLevel/>
<br/>

<Alllevels/>
    </div>
  )
}

export default LevelsMainPage