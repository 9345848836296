import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Get_All_Packages, Delete_Package } from '../../../actions/packages';
import { Dropdown, DropdownButton, Table } from 'react-bootstrap';
import { FcDeleteDatabase } from "react-icons/fc";
import { MdEditCalendar } from "react-icons/md";
import Edit from './edit';
import ViewPackage from './viewPackage';
import { HomePage } from '../../../translations/trans';

function PackagesList({ handleToggle }) {
  const dispatch = useDispatch();

  const ondelete = async (id) => {
    try {
      await dispatch(Delete_Package({ id: id }));
    } catch (err) {
      console.warn(err.message);
    }
  };

  const getPacks = async () => {
    try {
      await dispatch(Get_All_Packages());
    } catch (err) {
      console.warn(err.message);
    }
  };

  useEffect(() => {
    getPacks();
  }, [dispatch]);

  const { packages } = useSelector(({ packages }) => ({ packages }));

  const { lang, dir } = useSelector(({ language }) => {
    return { lang: language.lang, dir: language.dir };
  });

  return (
    <>
      <h2>{HomePage.thePrograms[lang]}</h2>
      <Table responsive>
        <thead>
          <tr>
            <th>{HomePage.nameForm[lang]}</th>
            <th>{HomePage.price[lang]}</th>
            <th>{HomePage.edit[lang]}</th>
            <th>{HomePage.delete[lang]}</th>
          </tr>
        </thead>
        <tbody>
          {packages.map((pack) => (
            <tr key={pack._id}>
              <td>{pack.name}</td>
              <td>{pack.price}</td>
              <td>
                <DropdownButton id="dropdown-basic-button" title="اختيارات ">
                  <Dropdown.Item onClick={() => handleToggle(pack)}>
                    <Edit pack={pack} />
                  </Dropdown.Item>
                  <Dropdown.Item>
                    <ViewPackage pack={pack} />
                  </Dropdown.Item>
                </DropdownButton>
              </td>
              <td>
                <FcDeleteDatabase
                  onClick={() => ondelete(pack._id)}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
}

export default PackagesList;
