import {getClasses, addStudentsToClass, changeClassActiveState, newClass, createMeetingRoom } from "../utils/class";
import { updateRequest } from "../utils/packagesRequests";

export const NEW_CLASS = 'NEW_CLASS';
const newclass = (Class)=>{
    return {
        type:NEW_CLASS,
        Class
    }
}
export const New_Class = (data)=>{
    return dispatch=>newClass(data).then(res=>{
        if(res.data.case === true){
            dispatch(newclass(res.data.class))
        }
        return res
    })
}

// get all classes 
export const GET_CLASSES = 'GET_CLASSES';
const  getclasses = (classes)=>{
    return {
        type:GET_CLASSES,
        classes
    }
}
export const Get_Classes = ()=>{
    return dispatch => getClasses().then(res=>{
        if(res.data.case){
            dispatch(getclasses(res.data.classes))
        }
        return res
    })
}
// add students to class
export const ADD_STUDENTS = 'ADD_STUDENTS';
const addStudents = (Class)=>{
    return {
        type:ADD_STUDENTS,
        Class
    }
}
export const Add_Students_To_Class = (data)=>{ // data{students,ids}
    return dispatch => addStudentsToClass(data).then(res=>{
        if(res.data.case){
            dispatch(addStudents(res.data.class))
        }
        return res
    })
}

// change class active state 
export const CHANGE_ACTIVE_STATE = 'CHANGE_ACTIVE_STATE';
const changeActiveState = (Class)=>{
    return{
        type:CHANGE_ACTIVE_STATE,
        Class
    }
}
export const Change_Class_Active_State = (data)=>{
    return dispatch=> changeClassActiveState(data).then(res=>{
        if(res.data.case){
            dispatch(changeActiveState(res.data.class))
        }
        return res
    })
}

export const CREATE_ROOM_ID = 'CREATE_ROOM_ID';
 const addRoomId = (Class)=>{
    return{
   type:CREATE_ROOM_ID,
   Class
    }
}
export const Add_Room = (classId)=>{
    return dispatch =>createMeetingRoom(classId).then(res=>{
        if(res.data.case){
            dispatch(addRoomId(res.data.class))
        }
        return res
    })
}
