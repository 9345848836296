import React, { useEffect, useState } from 'react';
import { Button, Card, Col } from 'react-bootstrap';
import { getLevelById } from '../../../utils/API';
import { getMaterialByCode } from '../../../utils/materials';
import CardCover from "../../../assets/programCover.png";
import Subscripe from './subscripe';
import { useSelector } from 'react-redux';
import NavButton from '../../styled-components/navButton';
import { NavLink } from 'react-router-dom';
import { HomePage } from '../../../translations/trans';

function PackageCard({ packageItem }) {
  const [level, setLevel] = useState('');
  const [material, setMaterial] = useState('');

  const fetchLevel = async (levelId) => {
    try {
      const res = await getLevelById({ _id: levelId });
      setLevel(res.data.level);
    } catch (err) {
      console.log(err.message);
    }
  }

  const fetchMaterial = async (code) => {
    try {
      console.log('code', code)
      const res = await getMaterialByCode({ code });
      setMaterial(res.data.material);
    } catch (err) {
      console.log(err.message);
    }
  }

  useEffect(() => {
    try {
      if (packageItem) {
        fetchLevel(packageItem?.levelId);
        fetchMaterial(packageItem?.materials[0]?.materialCode);
      }
    }
    catch (err) {
      console.warn(err.message)
    }
  }, [packageItem]);


  const { user } = useSelector(({ loggedUser }) => {
    return { user: loggedUser }
  })

  const { lang, dir } = useSelector(({ language }) => {
    return { lang: language.lang, dir: language.dir };
  });
  console.log(packageItem)
  return (
    <Col key={packageItem._id} md={3}>
      <Card className="mb-3">
        <Card.Body>
          <Card.Img src={CardCover}>
          </Card.Img>
          <div className='mt-4'>
            <Card.Title>{level?.name}</Card.Title>
            <Card.Subtitle>{material?.name}</Card.Subtitle>
            <Card.Title>{packageItem?.name}</Card.Title>
            <Card.Subtitle style={{ color: 'red', paddingBottom: '10px' }}>السعر: {packageItem.price === 0 ? "مجانا" : packageItem.price + 'ج.م'} </Card.Subtitle>

          </div>
          <div className=" text-center  w-75" style={{ margin: "10px auto" }}>

            <Subscripe packData={packageItem} user={user} />

            <NavLink id="hero3" to={`/subscription/${packageItem._id}`} className=" mt-4  header-call-us" style={{ textDecoration: "none", padding: "8px", border: "1px solid #1F3662", color: "#1F3662" }}>
              {HomePage.details[lang]}
            </NavLink>
          </div>
        </Card.Body>
      </Card>
    </Col>
  );
}

export default PackageCard;
