import axios from "axios"
import { urlBase } from "./API"

// Api For Add New Session
export const addSession = (data) => {
    return axios({
        method: "PUT",
        url: `${urlBase}/api/v2/add/new/session`,
        data: data
        // {
        //     "note": "sessionone",
        //     "date": "2024-08-29T00:00:00+03:00",
        //     "materialCode": "705",
        //     "lesson": "66c76a73ceafe500638e694d",
        //     "classId": "66cef678ec8deb006471a864",
        //     "duration": 120, // in minutes 
        //     "settings": {
        //         "auto_recording": "local" // cloud | local | none     default: local
        //     }
        // }
    })
}

// Api For Get Session for class
export const getSession = (data) => {
    console.log(data)
    return axios({
        method: "POST",
        url: `${urlBase}/api/get-all-sessions`,
        data: data
        // {
        //     "classId": "66cef678ec8deb006471a864"
        //  }
    })
}


// Api Fo Delete Session
export const deleteSession = (data) => {
    console.log(data)
    return axios({
        method: "DELETE",
        url: `${urlBase}/api/delete-session-by-id`,
        data: data
        // {
        //     "classId": "66caf5638fd90bc8ad0744b5",
        //     "sessionId": "66cb0f0fe0c0502dfa76b36d"
        // }
    })
}