import React, { useEffect } from 'react'
// import tests of a lesson 
import { getTestsByIds } from '../../../utils/tests'
function LessonTests({testsIDS}) {

    useEffect(()=>{},[])
  return (
    <>
    
    </>
  )
}

export default LessonTests