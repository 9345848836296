import React from 'react'
import { NavLink, Outlet } from 'react-router-dom';

function Manage({ taps }) {
  // console.log(taps)
  return (
    <>
      <div className='teacher-taps'>
        {taps.map((t, i) => <NavLink
          activeClass="activ"
          to={t.path} id={'tach-taps-1' + i}>{t.name}</NavLink>)}
        {/* <NavLink
            activeClass="activ" 
            to={taps[0].path} id='tach-taps-1'>{taps[0].name}</NavLink>
            <NavLink activeClass="activ" to={taps[1].path} id='tach-taps-2'>{taps[1].name}</NavLink> */}
      </div>
      <div className='manage-body'>
        <Outlet />
      </div>

    </>
  )
}

export default Manage