import React, { useEffect, useState } from 'react';
import { Button, Modal, Form, Alert } from 'react-bootstrap'; // Import Alert from react-bootstrap
import { createRequest as sendRequest,updateRequest } from '../../../utils/packagesRequests';
import {  useSelector } from 'react-redux';

function NewRequest({ userID, valid }) {
  const [packData, setPackData] = useState({});
  const [message, setMessage] = useState(null);
  const [showModal, setShowModal] = useState(false);
  
  // update request state
  const [update, setUpdate] = useState(false)
  const [existRequestId,setexistRequestId] = useState(null)
  const { packages } = useSelector(({ packages }) => {
    return { packages };
  });

  const makeRequest = async () => {
    try {

      if(!packData) return setMessage({case:false,message:'من فضلك اختر برنامج!'})
      if(!userID) return setMessage({case:false,message:'من فضلك قم بتحديث الصفحة !'})
      
      const res = await sendRequest({ userID, packData,packID:packData._id, valid });

      setMessage({ message: res.data.message, case: res.data.case });
      
      if(res.data.message==='exist'){

        setUpdate(true);
        setexistRequestId(res.data._id);
      }

    } catch (err) {
      console.warn(err.message);
      setMessage({ message: 'An error occurred.', case: false }); // Set case to false for error
    }
  };

//update 
const UpdateRequest = async () => {
  try {
    if(!packData) return setMessage({case:false,message:'من فضلك اختر برنامج!'})

    const res = await updateRequest({ _id:existRequestId,userID, packData,packID:packData._id, valid });

    setMessage({ message: res.data.message, case: res.data.case });
    

  } catch (err) {
    console.warn(err.message);
    setMessage({ message: 'An error occurred.', case: false }); // Set case to false for error
  }
};

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setMessage(null);
    }, 5000);

    return () => clearTimeout(timeoutId);
  }, [message]);

  const handleModalClose = () => {
    setShowModal(false);
    setUpdate(false)
  };

  const handleModalShow = () => {
    setShowModal(true);
  };

  const handlePackageSelect = (event) => {
    const selectedPackID = event.target.value;
    const [p] = packages.filter((p)=>p._id===selectedPackID)
    setPackData(p);
    setUpdate(false)
  };

  return (
    <div>
      <Button style={{width:'100px' ,fontSize:'15px'}} variant="primary" onClick={handleModalShow}>
        اضف برنامج 
      </Button>

      <Modal show={showModal} onHide={handleModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>اضافة برنامج</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group controlId="exampleForm.ControlSelect1">
            <Form.Label>اختر البرنامج:</Form.Label>
            <Form.Control as="select" onChange={handlePackageSelect}>
              <option value={null}>
                اختيار البرنامج
              </option>
              {packages.map((packageItem) => (
                <option key={packageItem._id} value={packageItem._id}>
                  {packageItem.name}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
          {message && (
            <Alert variant={message.case ? 'success' : 'danger'}>
              {message.message}
            </Alert>
          )}
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={handleModalClose}>
            اغلاق
          </Button>
          {update?<Button variant="primary" onClick={() => { UpdateRequest(); }}>
             تحديث
          </Button>:<Button variant="primary" onClick={() => { makeRequest(); }}>
            حفظ التغييرات
          </Button>}
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default NewRequest;
