import React, { useEffect } from 'react'
import MessagesCont from '../message/messages';
import {useDispatch, useSelector} from 'react-redux';
import { Inetialize_Ads } from '../../actions/login&register';
function TeacherMessages() {
  const dispatch  = useDispatch();
  const user = useSelector(state=>state.loggedUser?.user)
  useEffect(()=>{
    dispatch(Inetialize_Ads())
  },[])
  const students = useSelector(state=>state.users.students);
console.log('sent',user.sent)
  return (  
    <MessagesCont sent={user.sent} recived={user.recived} users={students}/>
  )
}

export default TeacherMessages