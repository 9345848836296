import React from "react";
import { useRef } from "react";
import { useSelector } from "react-redux";
import { uploading } from "../../utils/API";
import "./upload.css";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { urlBase } from "../../utils/API.js";
import FileUpload from "../file-upload/FileUpload.js"

import Progress from "./progress";
import axios from "axios";

function Upload() {
  const name = useRef();
  const files = useRef();
  const video = useRef();
  const navigate = useNavigate();
  const [uploadPercentage, setUploadPercentage] = useState(0);
  const user = useSelector(mapStateToProps);
  const img = user.personalImage
   
  const [imageLink, setImageLink] = useState(img);


  return (
    <div className="container">
       <FileUpload user={user} type={'image'}/>
      {/* {user.personalImage && (
        <img src={imageLink} width="130px" height="150px" />
      )}
      <h1>File Upload</h1>
      <Progress percentage={uploadPercentage} />

      <form id="form" onSubmit={fileUpload} encType="multipart/form-data">
        <div className="input-group">
          <label htmlFor="files">
            Personal Image : <span>.jpg, .png, .jpeg</span>
          </label>
          <input
            ref={files}
            id="files"
            name="file"
            type="file"
            accept=".jpg, .png, .jpeg, .gif, .bmp, .tif, .tiff|image/*"
            required
          />
        </div>
        <button className="submit-btn" type="submit">
          Upload
        </button>
      </form> */}
    </div>
  );
}
function mapStateToProps({ loggedUser }) {
  return { ...loggedUser.user, auth: loggedUser.auth };
}
export default Upload;
