import React,{useState,useEffect} from 'react'
import Col from "../styled-components/col";
import {FcPlus,FcMinus } from "react-icons/fc";
import {createArray} from "../globalFunc";
import {useDispatch} from "react-redux"
import {Set_Day,Delete_Day,Set_Schedual,Set_Breaks,Delete_Break} from "../../actions/schedual"
function Row({index,name,oldSchedual}) {
    const dispatch =useDispatch()
    const [checked,setChecked] = useState(false) // if day checked (true) show "Available" and " break" parts
    useEffect(()=>{
        if(oldSchedual){
            setChecked(true);
            setBreaks(oldSchedual.breaks.from.length)
        } 
        
    },[])
    
    const [breaks,setBreaks] = useState(0)
    const breaksArray =createArray(breaks)
  return (
    <>
            <Col width="150px" align="center"  justify='start'  > 

          <input
            type="checkbox"
            name={name}// no nee for name
            className="day"
            id={name+index}
            placeholder={name}
            checked={checked}
            onChange={()=>{
                if(!checked) {dispatch(Set_Day(index))};
                if(checked){dispatch(Delete_Day(index))}
                setChecked(!checked);

            }}
          />
          <label htmlFor={name+index}> {name}</label>
        </Col>
        {checked&&<>

          <div className="edit-breaks">
          <FcPlus  size='2em' 
          style={{ cursor:"pointer"  }}
           onClick={()=>setBreaks(breaks+1)}/> 
           <FcMinus size="1.7em" style={{ cursor:"pointer" ,backgroundColor:'#4CAF50' ,borderRadius:'50%' }} 
            onClick={()=>{
                if(breaks>0){
                    dispatch(Delete_Break(index,breaks-1))
                setBreaks(breaks-1);
                }}}/> 
           </div>
        <Col width="300px" align="center" justify="center" perfix='schedual-section' >
          <label htmlFor={index + "start"}>Available : </label>
          <input
            type="number"
            id={index + "start"}
            name={'from'}
            placeholder="start"
            defaultValue={oldSchedual&&oldSchedual.avail.from}  
            onChange={(event)=>{
                dispatch(Set_Schedual(index,'avail','from',event.target.value))
            }}              
            required   
          />
          
          <label htmlFor={index + "end"}> </label>
          <input
            type="number"
            id={index + "end"}
            name={"to"}
            placeholder="end"
            defaultValue={oldSchedual&&oldSchedual.avail.to}  

            onChange={(event)=>{
                dispatch(Set_Schedual(index,'avail','to',event.target.value))
            }}
            required
            
          />
        </Col>
        {/* ----------------breaks----------------- */}
        {breaksArray.map((b)=>
         <Col key={b} width="300px" align="center" justify="center" perfix='schedual-section' >
         <label htmlFor={b + "from"}>Break {b+1} : </label>
         <input
           type="number"
           id={b+"from"}
           defaultValue={oldSchedual&&oldSchedual.breaks.from[b]}  
           className={b} // subIndex
           name={"from"}// subKey, key is index
           onChange={(event)=>{
            dispatch(Set_Breaks(index,b,'breaks','from',event.target.value))
           }}
           placeholder="start"
           required
           
         />
         <label htmlFor={b + "to"}> </label>
         <input
           type="number"
           id={b + "to"}
           defaultValue={oldSchedual&&oldSchedual.breaks.to[b]}  
           className={b}
           name={"to"}
           onChange={(event)=>{
            dispatch(Set_Breaks(index,b,'breaks','to',event.target.value))
           }}
           placeholder="end"
           required
           
         />
       </Col>
         )}
        </>
        
        }

    </>
  )
}

export default Row